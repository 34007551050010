import React from 'react';
import styled from 'styled-components';

const Code = styled.pre`
  font-size: 14px;
  background-color: #2f2f2f;
  color: #ff0000;
  min-width: 250px;
  display: inline-block;
  padding: 5px;
  margin: 5px;
`;

export default function JsonDumper(props: any) {
  return (
    <span>
      <Code>{JSON.stringify(props.value, null, 4)}</Code>
    </span>
  );
}
