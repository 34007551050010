import React from 'react';
import styled from 'styled-components';
import { ReactComponent as FullstackIcon } from 'modules/../images/icons/title_fullstack_icon.svg';
import { ReactComponent as BackendIcon } from 'modules/../images/icons/title_backend_icon.svg';
import { ReactComponent as FrontendIcon } from 'modules/../images/icons/title_frontend_icon.svg';
import { ReactComponent as MobileIcon } from 'modules/../images/icons/title_mobile_icon.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Item = styled.div`
  width: 72px;
  height: 45px;

  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(24, 33, 53, 0.05);
  border-radius: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border: 1px solid #a4a2a2;

  &.selected {
    background: rgba(47, 128, 237, 0.05);
    border: 2px solid #2f80ed;
    color: #136afb;
  }

  &:hover {
    border: 2px solid #2f90ed;
  }
`;

const ItemLogo = styled.div`
  margin-right: 20px;

  &.selected {
    stroke: #136afb;
  }
`;

const ItemLabel = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;

  color: #5b6175;
  user-select: none;

  &.selected {
    color: #136afb;
  }
`;

const options = [
  {
    value: '1_10',
    label: '1-10',
    icon: <FullstackIcon />,
  },
  {
    value: '11_50',
    label: '11-50',
    icon: <FullstackIcon />,
  },
  {
    value: '51_250',
    label: '51-250',
    icon: <FullstackIcon />,
  },
  {
    value: '251_1k',
    label: '251-1k',
    icon: <FullstackIcon />,
  },
  {
    value: '1k+',
    label: '1k +',
    icon: <FullstackIcon />,
  },
];

function isSelected(selected, item) {
  return selected === item;
}

export default function CompanySizeSelection(props) {
  let selected = props.input.value;

  if (selected === '') {
    // selected = [];
  }

  return (
    <Container>
      {options.map((i) => {
        return (
          <Item
            key={i.value}
            className={isSelected(selected, i.value) ? 'selected' : ''}
            onClick={() => {
              props.input.onChange(i.value);
            }}
          >
            <ItemLabel className={isSelected(selected, i.value) ? 'selected' : ''}>
              {i.label}
            </ItemLabel>
          </Item>
        );
      })}
    </Container>
  );
}
