import React from 'react';
import styled from 'styled-components';
import SurveyFirstStepSidebar from './SurveyFirstStepSidebar';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import CustomerSurveyRoleSelection from './CustomerSurveyRoleSelection';
import CustomerSurveyNumberSelection from './CustomerSurveyNumberSelection';
import CustomerSkillSelection from './CustomerSkillSelection';
import axios from 'axios';
import * as yup from 'yup';
import JsonDumper from '../../core/components/JsonDumper';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f6f8f7;
  display: grid;
  grid-template-columns: 415px auto;
  grid-template-rows: 100%;
`;

const FormContainer = styled.div`
  background-color: #f6f8f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: auto;
  padding: 20px 0;
`;

const FormContent = styled.div`
  width: 600px;
  margin: 0 auto;
  padding: 0px;
  margin-top: 40px;
  min-height: min-content;
`;

const FormField = styled.div`
  &:not(:first-child) {
    margin-top: 22px;
  }
`;

const FormLabel = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #182135;
`;

const FormLabelInfo = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  margin-top: 4px;

  color: #5c6371;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 40px 0 10px 0;
`;

const Spacer = styled.div`
  flex: 1;
`;

const validationSchema = yup.object().shape({
  requiredRoles: yup.array().min(1),
  requiredSkills: yup.array().min(1),
  requiredNumberOfDevelopers: yup.string().nullable().required(),
});

export default class SurveyStep1 extends React.Component {
  state = {
    skills: null,
    submitting: false,
  };

  componentDidMount() {
    axios.get('/api/app/select-options/skills').then((response) => {
      this.setState({
        skills: response.data.data,
      });
    });
  }

  handleSubmit(values) {
    this.setState({
      submitting: true,
    });

    axios
      .post('/api/customers/signup-survey', values)
      .then((response) => {
        this.props.refreshSurvey(response.data.data);
        this.setState({
          submitting: false,
        });
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    return (
      <Container>
        <SurveyFirstStepSidebar />
        {this.state.skills !== null && (
          <FormContainer>
            <Spacer></Spacer>
            <Form
              onSubmit={(e) => {
                this.handleSubmit(e);
              }}
              mutators={{
                ...arrayMutators,
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              initialValues={this.props.survey}
              render={({ handleSubmit, form, submitting, pristine, values }) => {
                return (
                  <FormContent>
                    <FormField>
                      <FormLabel>Type of developers</FormLabel>
                      <FormLabelInfo style={{ marginBottom: 18 }}>
                        Select all that apply
                      </FormLabelInfo>
                      <Field name={'requiredRoles'} component={CustomerSurveyRoleSelection} />
                    </FormField>

                    <FormField>
                      <FormLabel>Skills</FormLabel>
                      <FormLabelInfo style={{ marginBottom: 18 }}>
                        Select at least one
                      </FormLabelInfo>
                      <Field
                        name={'requiredSkills'}
                        component={CustomerSkillSelection}
                        skills={this.state.skills}
                      />
                    </FormField>

                    <FormField>
                      <FormLabel style={{ marginBottom: 18 }}>
                        Number of developers to hire
                      </FormLabel>
                      <Field
                        name={'requiredNumberOfDevelopers'}
                        component={CustomerSurveyNumberSelection}
                      />
                    </FormField>
                    <ButtonContainer>
                      <button
                        style={{
                          fontWeight: 'bold',
                          fontSize: 14,
                        }}
                        disabled={!validationSchema.isValidSync(values) || this.state.submitting}
                        className="AppButton --primary --large"
                        onClick={(e) => {
                          form.submit();
                        }}
                      >
                        {this.state.submitting ? 'Submitting' : 'Continue'}
                      </button>
                    </ButtonContainer>
                  </FormContent>
                );
              }}
            />
            <Spacer />
          </FormContainer>
        )}
      </Container>
    );
  }
}
