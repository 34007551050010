import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -5px;
  
  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const ItemContainer = styled.div`
  padding: 5px;
  width: 50%;

  @media screen and (min-width: 768px) {
    width: auto;
    flex: 1 1 auto;
  }
`;

const Item = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #263238;
  
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  background-color: white;
  border-radius: 6px;
  
  width: 100%;
  height: 48px;
  
  padding: 0 8px;
  
  user-focus: none;
  cursor: pointer;

  box-shadow: 0px 4px 60px rgba(24, 33, 53, 0.05);
  box-sizing: border-box;
  border: 1px solid transparent;

  @media screen and (min-width: 768px) {
    width: 100%;
  }
  
  &.active {
    color: #136AFB;
    background: linear-gradient(0deg, rgba(16, 92, 247, 0.05), rgba(16, 92, 247, 0.05)), #FFFFFF;
    border: 1px solid #105CF7;
  }
`

export default function SurveyItemSelection(props) {

    let options = props.options;
    let value = props.input.value;
    let onChange = props.input.onChange;

    return (
        <Container>
            {options.map((item) => {
                return (
                    <ItemContainer>
                        <Item
                            className={(value === item.value) ? "active" : ""}
                            onClick={() => {
                            onChange(item.value);
                        }}>
                            {item.label}
                        </Item>
                    </ItemContainer>
                )
            })}
        </Container>
    )

}