import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ThreeProfilesIllustation } from 'modules/../images/illustrations/3_profiles.svg';
import { ReactComponent as CompanyIcons } from 'modules/../images/illustrations/marketing_company_backers.svg';
import { ReactComponent as NotchLogo } from 'modules/../images/icons/notch_logo_white.svg';
import { ReactComponent as Clock } from 'modules/../images/icons/clock_arrow.svg';
import { ReactComponent as Diamond } from 'modules/../images/icons/diamond.svg';
import { ReactComponent as UserProfile } from 'modules/../images/icons/user_profile.svg';
import { ReactComponent as Shield } from 'modules/../images/icons/shield.svg';

const Sidebar = styled.div`
  background-color: #136afb;

  display: flex;
  flex-direction: column;

  padding: 0 40px;
`;

const Logo = styled.div`
  margin-top: 40px;

  svg {
    height: auto;
    width: 80px;
  }
`;

const SpacerHeader = styled.div`
  width: 100%;
  min-height: 40px;
  max-height: 120px;
  flex-grow: 2;
`;

const SpacerBacked = styled.div`
  width: 100%;
  min-height: 10px;
  flex-grow: 1;
`;

const SidebarHeader = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;

  color: #ffffff;
`;

const FeatureList = styled.div`
  margin-top: 40px;
`;

const Feature = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FeatureLogo = styled.div`
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 15px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const FeatureText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;
  width: 286px;
`;

const Illustration = styled.div`
  margin: 0px auto 0 auto;

  svg {
    width: 150px;
  }

  @media screen and (max-height: 680px) {
    display: none;
  }
`;

const BackedByText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  margin: 0px auto 0 auto;

  text-align: center;

  color: #ffffff;
`;

const CompanyList = styled.div`
  margin: 16px auto 20px auto;

  svg {
    width: 340px;
  }
`;

export default function SurveyFirstStepSidebar() {
  return (
    <Sidebar>
      <Logo>
        <NotchLogo />
      </Logo>
      <SpacerHeader />
      <SidebarHeader>
        Find trusted remote
        <br /> developers ready to start
      </SidebarHeader>
      <FeatureList>
        <Feature>
          <FeatureLogo>
            <Clock />
          </FeatureLogo>
          <FeatureText>
            Get a shortlist of senior developers that work in your timezone within 72 hours
          </FeatureText>
        </Feature>

        <Feature>
          <FeatureLogo>
            <Diamond style={{ paddingTop: 2 }} />
          </FeatureLogo>
          <FeatureText>
            Review in-depth profiles of vetted talent and fast-track straight to interviews
          </FeatureText>
        </Feature>

        <Feature>
          <FeatureLogo>
            <UserProfile />
          </FeatureLogo>
          <FeatureText>
            Automate your remote team management with automatic payments and time tracking
          </FeatureText>
        </Feature>

        <Feature>
          <FeatureLogo>
            <Shield style={{ paddingTop: 2 }} />
          </FeatureLogo>
          <FeatureText>Start without risk with a 30 day evaluation period</FeatureText>
        </Feature>
      </FeatureList>

      <Illustration>
        <ThreeProfilesIllustation />
      </Illustration>

      <SpacerBacked />
      <BackedByText>Trusted by companies backed by the best</BackedByText>
      <CompanyList>
        <CompanyIcons />
      </CompanyList>
    </Sidebar>
  );
}
