import React, { Component } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

const ErrorText = styled.div`
  color: red;
  font-size: 10px;
  max-height: 10px;
`;

export default function PhoneNumberInput(props) {
    return (
        <>
            <PhoneInput
                {...props}
                country="us"
                placeholder={props.placeholder}
                inputStyle={{
                    width: '100%',
                    fontSize: 14,
                    color: '#262626',
                    border: '1px solid #d0d5de',
                    borderRadius: 7,
                    height: 57,
                    fontWeight: 600,
                }}
                containerStyle={{ width: '100%' }}
                dropdownStyle={{ backgroundColor: 'white' }}
                buttonStyle={{ backgroundColor: 'white', borderRight: 'none' }}
                value={props.input.value}
                onChange={(e) => props.input.onChange(e)}
            />
            <ErrorText>{props.meta.error && !props.meta.pristine && 'The link is not valid'}</ErrorText>
        </>
    );
}
