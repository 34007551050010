import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { isArray } from 'util';

const Container = styled.div`
  width: 100%;
  height: 50px;

  display: flex;
  flex-direction: column-reverse;
`;

const stylesOption = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: '100%',
    height: 48,
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    color: 'red',
    display: 'none',
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    display: state.isMulti ? 'none' : 'default',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 40,
    border: '1px solid #eceff6',
    borderTop: 'none',
    boxShadow: 'none',
    zIndex: 1000,
    maxHeight: 100,
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 48,
    zIndex: 1000,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 40,
    border: state.isFocused ? '#7aa0ff 1px solid' : '#eaecf4  1px solid',
    paddingLeft: 10,
    boxShadow: '0px 20px 40px rgba(39, 51, 57, 0.05)',
    '&:hover': {
      borderColor: '#7aa0ff 1px solid',
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    color: state.isDisabled ? '#999' : '#333333',
    letterSpacing: '0',
    fontWeight: 500,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 10,
    color: '#ffffff',
    backgroundColor: '#136afb',
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: 10,
    color: '#ffffff',
    backgroundColor: '#136afb',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    lineHeight: 15,
    marginLeft: 0,
    fontWeight: 500,
    color: '#263238',
    opacity: '0.6',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: state.isSelected ? '#7aa0ff' : state.isFocused ? '#edf3fc' : 'white',
    color: state.isSelected ? 'white' : state.isFocused ? '#7aa0ff' : '#3c3c3c',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
};

function prepareOptions(options: any, selectedOptions: any) {
  if (options !== undefined) {
    if (options.length == 0) {
      return [];
    }

    var result = options.map(function (e: any) {
      if (
        selectedOptions.find((o: any) => {
          return o.code == e.code;
        })
      ) {
        return null;
      }

      return { value: e.code, label: e.name };
    });

    result = result.filter(function (e: any) {
      return e !== null;
    });
    return result;
  } else {
    return [];
  }
}

function handleChange() {}

function getByValue(value: any) {
  return { value: value, label: value };
}

function parseOptions(options, excludeValues) {
  if (Array.isArray(excludeValues)) {
    let newOptions = options.filter((e) => {
      let found = excludeValues.find((d) => {
        return d === e.value;
      });
      return found === undefined;
    });
    return newOptions;
  } else {
    return options;
  }
}

function getOptionByValue(options: any, value: any) {
  if (value === '') {
    return null;
  }

  if (isArray(value)) {
    let selectedOptions: any[] = [];
    for (let item of value) {
      var found = options.find((o: any) => {
        return o.value == item;
      });
      selectedOptions.push(found);
    }
    return selectedOptions;
  } else {
    return options.find((o: any) => {
      return o.value == value;
    });
  }

  return null;
}

const DropDownContainer = styled.div`
  width: 30px;
  height: 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-left: auto;
  margin-right: 10px;
  svg {
  }
`;

const CustomDropdownIndicator = ({ innerRef, innerProps }) => {
  return (
    <DropDownContainer>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.75 5.25L6 9L2.25 5.25"
          stroke="#263238"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </DropDownContainer>
  );
};

export default function CommonSelect(props: any) {
  let customStyle = null;

  if (props.stylesOption === null || props.stylesOption === undefined) {
    customStyle = stylesOption;
  } else {
    customStyle = props.stylesOption;
  }

  return (
    <Container>
      <Select
        value={getOptionByValue(props.options, props.input.value)}
        onChange={(e: any) => {
          if (e == null) {
            props.input.onChange(null);
            return;
          }
          if (isArray(e)) {
            let values: any[] = [];
            for (let item of e) {
              // @ts-ignore
              values.push(item.value);
              props.input.onChange(values);
            }
          } else {
            const newInput = e.value;
            props.input.onChange(newInput);
          }
        }}
        styles={customStyle}
        placeholder={props.placeholder}
        isMulti={props.isMulti}
        isDisabled={props.disabled}
        {...props}
        options={parseOptions(props.options, props.excludeValues)}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
      />
    </Container>
  );
}
