const SurveyOptions = {
    developersNeeded: [
        {value: '1', label: 'Just one'},
        {value: '2_3', label: '2 - 3'},
        {value: '4_5', label: '4 - 5'},
        {value: '5+', label: '5 +'}
    ],
    employeeNumber: [
        {value: '1_10', label: '1 - 10'},
        {value: '11_50', label: '11 - 50'},
        {value: '51_250', label: '51 - 250'},
        {value: '251_1000', label: '251 - 1000'},
        {value: '1000+', label: '1000+'},
    ],
    timeCommitment: [
        {value: 'full_time', label: 'Full-time (40 hours per week)'},
        {value: 'part_time', label: 'Part-time (20 hours per week)'},
        {value: 'open', label: 'Open to either'},
    ],
    earliestHireDate: [
        {value: 'immediately', label: 'Immediately'},
        {value: '1_week', label: '1 week'},
        {value: '2_week', label: '2+ week'},
        {value: '1_month', label: '1 month'},
        {value: 'specific', label: 'More than a month'},
    ],
    workingPeriod: [
        {value: '12+', label: 'Long-term (12+)'},
        {value: '3_12', label: '3 -12 months'},
        {value: '1_3', label: '1 - 3 months'},
        {value: '1', label: 'Short project (<1 month)'},
    ],
    hear: [
        {value: 'customer', label: 'Customer / Company'},
        {value: 'talent', label: 'Talent / Community'},
        {value: 'referred', label: 'Referred / Recommended'},
        {value: 'email', label: 'Email invite'},
        {value: 'partner', label: 'Notch partner'},
        {value: 'google', label: 'Google'},
        {value: 'facebook', label: 'Facebook'},
        {value: 'twitter', label: 'Twitter'},
        {value: 'linkedin', label: 'Linkedin'},
        {value: 'friend', label: 'Friend / Word of mouth'},
        {value: 'none', label: "I hadn't heard of Notch before"},
        {value: 'other', label: 'Other'},
    ]
};

export default SurveyOptions;