import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { isArray } from 'util';

const Container = styled.div`
  width: 100%;
  height: 50px;
`;

const stylesOption = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: '100%',
    height: 40,
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    color: 'red',
    display: 'none',
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    display: state.isMulti ? 'none' : 'default',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 40,
    border: '1px solid #eceff6',
    borderTop: 'none',
    boxShadow: 'none',
    zIndex: 1000,
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 48,
    zIndex: 1000,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 40,
    border: state.isFocused ? '#7aa0ff 1px solid' : '#eaecf4  1px solid',
    paddingLeft: 10,
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#7aa0ff 1px solid',
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    color: state.isDisabled ? '#999' : '#333333',
    letterSpacing: '0.3px',
    fontWeight: 400,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 10,
    color: '#ffffff',
    backgroundColor: '#136afb',
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: 10,
    color: '#ffffff',
    backgroundColor: '#136afb',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 11,
    lineHeight: 22,
    marginLeft: 15,
    fontWeight: 500,
    color: '#555',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    backgroundColor: state.isSelected ? '#7aa0ff' : state.isFocused ? '#edf3fc' : 'white',
    color: state.isSelected ? 'white' : state.isFocused ? '#7aa0ff' : '#6f6f6f',
  }),
};

function prepareOptions(options: any, selectedOptions: any) {
  if (options !== undefined) {
    if (options.length == 0) {
      return [];
    }

    var result = options.map(function (e: any) {
      if (
        selectedOptions.find((o: any) => {
          return o.code == e.code;
        })
      ) {
        return null;
      }

      return { value: e.code, label: e.name };
    });

    result = result.filter(function (e: any) {
      return e !== null;
    });
    return result;
  } else {
    return [];
  }
}

function handleChange() {}

function getByValue(value: any) {
  return { value: value, label: value };
}

function parseOptions(options, excludeValues) {
  if (Array.isArray(excludeValues)) {
    let newOptions = options.filter((e) => {
      let found = excludeValues.find((d) => {
        return d === e.value;
      });
      return found === undefined;
    });
    return newOptions;
  } else {
    return options;
  }
}

function getOptionByValue(options: any, value: any) {
  if (value === '') {
    return null;
  }

  if (isArray(value)) {
    let selectedOptions: any[] = [];
    for (let item of value) {
      var found = options.find((o: any) => {
        return o.value == item;
      });
      selectedOptions.push(found);
    }
    return selectedOptions;
  } else {
    return options.find((o: any) => {
      return o.value == value;
    });
  }

  return null;
}

export default function CommonSelect(props: any) {
  let customStyle = null;

  if (props.stylesOption === null || props.stylesOption === undefined) {
    customStyle = stylesOption;
  } else {
    customStyle = props.stylesOption;
  }

  return (
    <Container>
      <Select
        value={getOptionByValue(props.options, props.input.value)}
        onChange={(e: any) => {
          if (e == null) {
            props.input.onChange(null);
            return;
          }
          if (isArray(e)) {
            let values: any[] = [];
            for (let item of e) {
              // @ts-ignore
              values.push(item.value);
              props.input.onChange(values);
            }
          } else {
            const newInput = e.value;
            props.input.onChange(newInput);
          }
        }}
        styles={customStyle}
        placeholder={props.placeholder}
        isMulti={props.isMulti}
        isDisabled={props.disabled}
        {...props}
        options={parseOptions(props.options, props.excludeValues)}
      />
    </Container>
  );
}
