// @ts-nocheck
import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ChevronDown } from '../../../images/icons/feather/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../../images/icons/feather/chevron-up.svg';

const axios = require('axios').default;

interface Props {
  user: {
    nickname: String;
  };
  visible: Boolean;
  profileURL?: String;
}

const AccountMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-right: 40px;
`;
const AccountLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #74b9ff;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 800;
  color: #0984e3;
`;

const AccountPicture = styled.div<{ backgroundUrl: String }>`
  background-image: url('${(props) => props.backgroundUrl}');
  background-size: contain;
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;

const AccountName = styled.span`
  font-weight: 600;
  font-size: 10px;
  font-family: Montserrat;
  color: #444;
  user-select: none;
`;

const CompanyName = styled.div`
  font-size: 10px;
  color: #999;
`;

const AccountText = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 10px;
`;

const AccountIcon = styled.span`
  svg {
    stroke: #136afb;
  }
`;

const Menu = styled.div<{ visible: Boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  width: 200px;
  //height: 60px;
  top: 79px;
  right: 0px;
  border: #eaecf4 1px solid;
  //border-top: none;
  border-right: none;
  z-index: 100;
`;
const MenuLink = styled.span`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  font-weigth: 600;
  user-select: none;

  &:hover {
    color: #7aa0ff;
    transition: 0.1s;
  }
`;

const StyledNavLink = styled(NavLink)`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  font-weigth: 600;
  user-select: none;
  text-decoration: none;
  color: black;

  &:hover {
    color: #7aa0ff;
    transition: 0.1s;
  }
`;

class CustomerAccountMenu extends Component<Props> {
  static defaultProps: Props = {
    user: {
      nickname: '',
    },
    visible: false,
    profileURL: '',
  };

  state = {
    menuVisible: false,
  };

  handleMouseLeave(e) {
    this.toggleMenu();
  }

  returnImageUrlWithSubdomain(url) {
    url = url.replace('http://', 'http://app.');
    url = url.replace('https://', 'https://app.');
    return url;
  }

  toggleMenu() {
    let visibility = !this.state.menuVisible;

    this.setState({
      menuVisible: !this.state.menuVisible,
    });

    if (visibility) {
      setTimeout(() => {
        let menu = document.getElementById('account_menu');
        menu.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
      }, 100);
    }
  }

  componentDidMount() {}

  logout() {
    axios.post('/api/security/logout').then(() => {
      window.location.href = process.env.REACT_APP_DOMAIN_URL_WEBSITE + '/login';
    });
  }

  showMenuForUser() {
    if (this.props.user.status === 'review' || this.props.user.status === 'active') {
      return (
        <>
          <StyledNavLink to="/contract" onClick={this.toggleMenu.bind(this)}>
            Contract
          </StyledNavLink>
          <MenuLink onClick={this.logout.bind(this)}>Logout</MenuLink>
        </>
      );
    }

    return <MenuLink onClick={this.logout.bind(this)}>Logout</MenuLink>;
  }

  render() {
    var firstWord = this.props.user.fullname.replace(/ .*/, '');
    let nickname = this.props.user.nickname ?? firstWord;

    if (nickname.length > 28) {
      nickname = nickname.substring(0, 28) + '...';
    }

    let profileUrl = this.props.user.customer_logo;
    let hasProfilePicture = profileUrl !== null && profileUrl !== undefined;

    return (
      <Fragment>
        <AccountMenuContainer onClick={this.toggleMenu.bind(this)}>
          {hasProfilePicture ? (
            <AccountPicture backgroundUrl={this.returnImageUrlWithSubdomain(profileUrl)} />
          ) : (
            <AccountLogo>{nickname.charAt(0)}</AccountLogo>
          )}
          <AccountText>
            <AccountName>{nickname}</AccountName>
            {this.props.user.company_name && (
              <CompanyName>{this.props.user.company_name}</CompanyName>
            )}
          </AccountText>

          <AccountIcon>{this.state.menuVisible ? <ChevronUp /> : <ChevronDown />}</AccountIcon>
        </AccountMenuContainer>
        <Menu id="account_menu" visible={this.state.menuVisible}>
          {this.showMenuForUser()}
        </Menu>
      </Fragment>
    );
  }
}

export default CustomerAccountMenu;
