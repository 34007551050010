import React from 'react';
import JsonDumper from '../../core/components/JsonDumper';
import axios from 'axios';
import SurveyStep1 from './SurveyStep1';
import SurveyStep2 from './SurveyStep2';
import SurveyStep3 from './SurveyStep3';
import SurveyStep4 from './SurveyStep4';
import SurveyStep6 from './SurveyStep6';
import SurveyStep5 from './SurveyStep5';

export default class CustomerSurvey extends React.Component {
  state = {
    survey: null,
    loading: true,
  };

  componentDidMount() {
    axios.get('/api/customer/signup-survey').then((response) => {
      this.setState({
        survey: response.data.data,
        loading: false,
      });
    });
  }

  refreshSurvey(data) {
    let newData = Object.assign(data);
    this.setState({
      survey: newData,
    });
  }

  render() {
    if (this.state.loading === true) {
      return null;
    }

    let survey = this.state.survey;
    switch (survey.step) {
      case 1:
        return (
          <SurveyStep1
            refreshSurvey={this.refreshSurvey.bind(this)}
            survey={this.state.survey}
            user={this.props.user}
          />
        );
      case 2:
        return (
          <SurveyStep2
            refreshSurvey={this.refreshSurvey.bind(this)}
            survey={this.state.survey}
            user={this.props.user}
          />
        );
      case 3:
        return (
          <SurveyStep3
            refreshSurvey={this.refreshSurvey.bind(this)}
            survey={this.state.survey}
            user={this.props.user}
          />
        );
      case 4:
        return (
          <SurveyStep4
            refreshSurvey={this.refreshSurvey.bind(this)}
            survey={this.state.survey}
            user={this.props.user}
          />
        );
      case 5:
        return (
          <SurveyStep5
            refreshSurvey={this.refreshSurvey.bind(this)}
            survey={this.state.survey}
            user={this.props.user}
          />
        );
      case 6:
        return (
          <SurveyStep6
            refreshSurvey={this.refreshSurvey.bind(this)}
            survey={this.state.survey}
            user={this.props.user}
          />
        );
    }

    return <JsonDumper value={this.state.survey} />;
  }
}
