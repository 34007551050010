import React from 'react';
import styled from 'styled-components';
import SurveyFirstStepSidebar from './SurveyFirstStepSidebar';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import CustomerSurveyRoleSelection from './CustomerSurveyRoleSelection';
import CustomerSurveyNumberSelection from './CustomerSurveyNumberSelection';
import CustomerSkillSelection from './CustomerSkillSelection';
import axios from 'axios';
import * as yup from 'yup';
import JsonDumper from '../../core/components/JsonDumper';
import SurveyProgress from './SurveyProgress';
import CommonSelect from '../../core/components/FormFields/CommonSelect';
import TimeCommitmentSelection from './TimeCommitmentSelection';
import TextInput from '../../core/components/FormFields/TextInput';
import CompanySizeSelection from './CompanySizeSelection';
import LoadingAnimation from '../../core/components/LoadingAnimation';
import PhoneNumberInput from '../../core/components/FormFields/PhoneNumberInput';
import PhoneNumberTextInput from './PhoneNumberTextInput';
import { ReactComponent as CallIcon } from 'modules/../images/icons/survey_call_icon.svg';
import { ReactComponent as ScheduleIcon } from 'modules/../images/icons/survey_calendar_icon.svg';
import Header from './Header';

const validationSchema = yup.object().shape({
  phoneNumber: yup.string().nullable().required(),
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f8f7;
  width: 100vw;
  height: 100vh;
`;

const Card = styled.div`
  width: 800px;
  min-height: 450px;

  border-radius: 10px;
  background-color: white;

  margin: 0 auto 20px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormContent = styled.div`
  font-family: 'Open Sans';
  width: 430px;
  margin: 0 auto;
  padding: 0px;
  min-height: min-content;
`;

const FormField = styled.div`
  &:not(:first-child) {
    margin-top: 22px;
  }
`;

const FormLabel = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #767889;

  margin-bottom: 10px;
`;

const Content = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const FormLabelInfo = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  margin-top: 4px;

  color: #5c6371;
`;

const ButtonContainers = styled.div`
  margin: 10px auto 4px auto;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ProfilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: 28px;
`;

const CardHeader = styled.div`
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  color: #474a50;
  line-height: 30px;
`;

const CardBody = styled.div`
  font-weight: 400;
  font-size: 21px;
  text-align: center;
  color: #474a50;
`;

const CardInfo = styled.div`
  margin-top: 12px;
  line-height: 20px;
  font-size: 12px;
  color: #5c6371;
  font-family: 'Open Sans';
`;

const PictureList = styled.div`
  position: relative;

  width: 125px;
  height: 42px;
`;

const MoreTalent = styled.div`
  width: 35px;
  height: 35px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 13px;
  font-weight: 600;
  background-color: rgba(47, 128, 237, 0.05);
  color: #2f80ed;
  border-radius: 50%;
`;

const Picture = styled.div`
  position: absolute;

  width: 35px;
  height: 35px;

  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid white;
  }
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 205px;
  height: 42px;

  color: white;

  background: #136afb;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;

  span {
    font-size: 12px;
    font-weight: 700;
    font-family: 'Open Sans';
  }

  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  &:disabled {
    background-color: #90b9fd;
    border: none;
    cursor: default;
  }
`;

const CallLabel = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 9px;
  color: #5c6371;
`;

export default class SurveyStep4 extends React.Component {
  state = {
    submitting: false,
    loading: true,
    profiles: [],
  };

  returnImageUrlWithSubdomain(url) {
    url = url.replace('http://', 'http://app.');
    url = url.replace('https://', 'https://app.');
    return url;
  }

  componentDidMount() {
    axios.get('/api/customers/signup-survey/potential-talents').then((response) => {
      this.setState({
        profiles: response.data.data,
        loading: false,
      });
    });
  }

  handleSubmit(values) {
    this.setState({
      submitting: true,
    });

    axios
      .post('/api/customers/signup-survey', values)
      .then((response) => {
        this.props.refreshSurvey(response.data.data);
        this.setState({
          submitting: false,
        });
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <Container>
          <Header user={this.props.user} />
          <Content>
            <SurveyProgress step={4} />
            <Card>
              <LoadingAnimation />
            </Card>
          </Content>
        </Container>
      );
    }

    return (
      <Container>
        <Header user={this.props.user} />
        <Content>
          <SurveyProgress step={4} />
          <Form
            onSubmit={(e) => {
              this.handleSubmit(e);
            }}
            mutators={{
              ...arrayMutators,
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={this.props.survey}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <>
                  <Card>
                    <FormContent>
                      <ProfilesContainer>
                        <PictureList>
                          {this.state.profiles.map((i, k) => {
                            return (
                              <Picture style={{ left: (k + 1) * 20 - 20 }}>
                                <img src={this.returnImageUrlWithSubdomain(i)} />
                              </Picture>
                            );
                          })}
                        </PictureList>
                        <MoreTalent>25+</MoreTalent>
                      </ProfilesContainer>
                      <CardHeader>25+ vetted senior engineers</CardHeader>
                      <CardBody>match the skills for this role</CardBody>
                      <CardInfo>
                        Please enter your phone number below to connect with a hiring consultant so
                        we can find the right talent ready to join your team from anywhere.
                      </CardInfo>

                      <FormField>
                        <Field name={'phoneNumber'} component={PhoneNumberTextInput} />
                      </FormField>

                      <ButtonContainers>
                        <Button
                          style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                          }}
                          disabled={!validationSchema.isValidSync(values) || this.state.submitting}
                          onClick={(e) => {
                            form.mutators.setValue('callbackType', 'schedule_now');
                            form.submit();
                          }}
                        >
                          <CallIcon />
                          <span>Request callback now</span>
                        </Button>

                        <Button
                          style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                          }}
                          disabled={!validationSchema.isValidSync(values) || this.state.submitting}
                          onClick={(e) => {
                            form.mutators.setValue('callbackType', 'schedule_later');
                            form.submit();
                          }}
                        >
                          <ScheduleIcon />
                          <span>Schedule call later</span>
                        </Button>
                      </ButtonContainers>
                      <CallLabel>Average time to callback: 15 minutes.</CallLabel>
                    </FormContent>
                  </Card>
                </>
              );
            }}
          />
        </Content>
      </Container>
    );
  }
}
