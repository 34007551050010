import React from 'react';
import styled from 'styled-components';
import SurveyFirstStepSidebar from './SurveyFirstStepSidebar';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import CustomerSurveyRoleSelection from './CustomerSurveyRoleSelection';
import CustomerSurveyNumberSelection from './CustomerSurveyNumberSelection';
import CustomerSkillSelection from './CustomerSkillSelection';
import axios from 'axios';
import * as yup from 'yup';
import JsonDumper from '../../core/components/JsonDumper';
import SurveyProgress from './SurveyProgress';
import CommonSelect from '../../core/components/FormFields/CommonSelect';
import TimeCommitmentSelection from './TimeCommitmentSelection';
import TextInput from '../../core/components/FormFields/TextInput';
import CompanySizeSelection from './CompanySizeSelection';
import Header from './Header';

const validationSchema = yup.object().shape({
  companyName: yup.string().nullable().required(),
  websiteUrl: yup.string().nullable().required(),
  companySize: yup.string().nullable().required(),
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f8f7;
  width: 100vw;
  height: 100vh;
`;

const Content = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const Card = styled.div`
  width: 800px;
  min-height: 450px;
  border-radius: 10px;
  background-color: white;

  margin: 0 auto 20px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormContent = styled.div`
  width: 400px;
  margin: 0 auto;
  padding: 0px;
  min-height: min-content;
`;

const FormField = styled.div`
  &:not(:first-child) {
    margin-top: 42px;
  }
`;

const FormLabel = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #767889;

  margin-bottom: 10px;
`;

const FormLabelInfo = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  margin-top: 4px;

  color: #5c6371;
`;

const ButtonContainers = styled.div`
  margin: 0 auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default class SurveyStep3 extends React.Component {
  state = {
    submitting: false,
  };

  componentDidMount() {}

  handleSubmit(values) {
    this.setState({
      submitting: true,
    });

    axios
      .post('/api/customers/signup-survey', values)
      .then((response) => {
        this.props.refreshSurvey(response.data.data);
        this.setState({
          submitting: false,
        });
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
      });
  }

  handleBack() {
    axios
      .post('/api/customers/signup-survey/back')
      .then((response) => {
        this.props.refreshSurvey(response.data.data);
        this.setState({
          submitting: false,
        });
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    return (
      <Container>
        <Header user={this.props.user} />
        <Content>
          <SurveyProgress step={3} />
          <Form
            onSubmit={(e) => {
              this.handleSubmit(e);
            }}
            mutators={{
              ...arrayMutators,
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={this.props.survey}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <>
                  <Card>
                    <FormContent>
                      <FormField style={{}}>
                        <FormLabel>What's the name of your company?</FormLabel>
                        <Field name={'companyName'} component={TextInput} />
                      </FormField>

                      <FormField style={{}}>
                        <FormLabel>What is the website URL?</FormLabel>
                        <Field name={'websiteUrl'} component={TextInput} />
                      </FormField>

                      <FormField>
                        <FormLabel>How many employees do you have in your company?</FormLabel>
                        <Field name={'companySize'} component={CompanySizeSelection} />
                      </FormField>
                    </FormContent>
                  </Card>

                  <ButtonContainers>
                    <button
                      style={{
                        fontWeight: 'normal',
                        fontSize: 14,
                        marginRight: 20,
                      }}
                      disabled={this.state.submitting}
                      className="AppButton --cancel --large"
                      onClick={(e) => {
                        this.handleBack();
                      }}
                    >
                      {this.state.submitting ? 'Back' : 'Back'}
                    </button>
                    <button
                      style={{
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                      disabled={!validationSchema.isValidSync(values) || this.state.submitting}
                      className="AppButton --primary --large"
                      onClick={(e) => {
                        form.submit();
                      }}
                    >
                      {this.state.submitting ? 'Submitting' : 'Continue'}
                    </button>
                  </ButtonContainers>
                </>
              );
            }}
          />
        </Content>
      </Container>
    );
  }
}
