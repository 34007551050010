import React from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import * as yup from 'yup';
import arrayMutators from 'final-form-arrays';
import PreferredRoleSelection from '../survey2/PreferredRoleSelection';
import SurveyHeader from './SurveyHeader';
import CustomerSkillSelection from "./CustomerSkillSelection";
import SurveyItemSelection from "./SurveyItemSelection";
import SurveyOptions from "./SurveyOptions";
import JsonDumper from "../../core/components/JsonDumper";
import CommonSelect from "../../talent/survey2/CommonSelect";
import TextInput from "./TextInput";
import countries from "../../core/countries";
import {timezones} from "../../core/components/timezones_simple";
import PhoneNumberTextInput from "./PhoneNumberInput";
import axios from "axios";
import { ReactComponent as CallIcon } from 'modules/../images/icons/survey_call_icon.svg';
import { ReactComponent as ScheduleIcon } from 'modules/../images/icons/survey_calendar_icon.svg';
import LoadingAnimation from "../../core/components/LoadingAnimation";

const optionsTimezone = timezones.map((i) => {
    return { value: i.value, label: i.text };
});


const FormContainer = styled.div`
  grid-area: content;
  margin: 0 auto;
  width: 100%;
  max-width: 428px;
  padding-top: 120px;
  padding-bottom: 20px;

  @media screen and (min-width: 768px) {
    max-width: 600px;
  }
`;

const HeaderText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  margin-top: 40px;

  @media screen and (min-width: 768px) {
    font-size: 27px;
    line-height: 33px;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const FormInfoText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 40px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }

  color: #5c6371;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;


const ButtonContainers = styled.div`
  margin: 10px auto 4px auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

const ProfilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: 28px;
  margin-top: 20px;
`;

const CardHeader = styled.div`
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  color: #474a50;
  line-height: 30px;

  @media screen and (min-width: 768px) {
    text-align: center;
  }
`;

const CardBody = styled.div`
  font-weight: 400;
  font-size: 21px;
  text-align: center;
  color: #474a50;

  @media screen and (min-width: 768px) {
    text-align: center;
  }
`;

const CardInfo = styled.div`
  margin-top: 12px;
  line-height: 20px;
  font-size: 12px;
  color: #5c6371;
  font-family: 'Open Sans';
  padding: 0 0px;
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;

const PictureList = styled.div`
  position: relative;

  width: 125px;
  height: 42px;
`;

const MoreTalent = styled.div`
  width: 35px;
  height: 35px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 13px;
  font-weight: 600;
  background-color: rgba(47, 128, 237, 0.05);
  color: #2f80ed;
  border-radius: 50%;
`;

const Picture = styled.div`
  position: absolute;

  width: 35px;
  height: 35px;

  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid white;
  }
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 57px;
  margin-bottom: 20px;

  color: white;
  font-size: 14px;

  background: #136afb;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;

  font-family: Inter;

  &:disabled {
    background-color: #90b9fd;
    border: none;
    cursor: default;
  }
`;

const CallLabel = styled.div`
  font-family: Inter;
  font-size: 12px;
  color: #263228;
  margin-top: 8px;
  margin-bottom: 30px;
  font-weight: normal;
`;

const validationSchema = yup.object().shape({
    phoneNumber: yup.string().required(),
});

const Card = styled.div`
  max-width: 600px;
  min-height: 450px;
  
  border-radius: 10px;
  background-color: white;

  margin: 0 auto 20px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  
  padding: 40px 20px;
`;

const FormContent = styled.div`
  font-family: 'Open Sans';
  max-width: 430px;
  margin: 0 auto;
  padding: 0px;
  min-height: min-content;
`;

const FormField = styled.div`
  &:not(:first-child) {
    margin-top: 22px;
  }
`;

export default class SurveyStep3 extends React.Component{

    returnImageUrlWithSubdomain(url) {
        url = url.replace('http://', 'http://app.');
        url = url.replace('https://', 'https://app.');
        return url;
    }

    state = {
        submitting: false,
        loading: true,
        profiles: [],
    };

    componentDidMount() {
        window.setTimeout(() => {
            axios.get('/api/customers/signup-survey/potential-talents').then((response) => {
                this.setState({
                    profiles: response.data.data,
                    loading: false,
                });
            });
        }, 2000)
    }

    render() {


        let props = this.props;

        return (
            <>
                <Form
                    onSubmit={(values) => {
                        props.saveSurveyNext(values);
                    }}
                    mutators={{
                        ...arrayMutators,
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value);
                        },
                    }}
                    initialValues={props.data}
                    render={({handleSubmit, form, submitting, pristine, values}) => {
                        return (
                            <Container>
                                <SurveyHeader visible={true} {...props} percentage={0.85}/>
                                <FormContainer>

                                    <Card>
                                        {this.state.loading === false ? (
                                            <FormContent>
                                                <ProfilesContainer>
                                                    <PictureList>
                                                        {this.state.profiles.map((i, k) => {
                                                            return (
                                                                <Picture style={{left: (k + 1) * 20 - 20}}>
                                                                    <img src={this.returnImageUrlWithSubdomain(i)}/>
                                                                </Picture>
                                                            );
                                                        })}
                                                    </PictureList>
                                                    <MoreTalent>25+</MoreTalent>
                                                </ProfilesContainer>
                                                <CardHeader>25+ vetted senior engineers</CardHeader>
                                                <CardBody>match the skills for this role</CardBody>
                                                <CardInfo>
                                                    Please enter your phone number below to connect with a
                                                    hiring consultant so we can find the right highly-skilled and
                                                    remote-ready talent for your team
                                                </CardInfo>

                                                <FormField>
                                                    <Field name={'phoneNumber'} component={PhoneNumberTextInput}/>

                                                </FormField>
                                                <CallLabel>Your phone number will not be shared</CallLabel>

                                                <ButtonContainers>
                                                    <Button
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: 14,
                                                        }}
                                                        disabled={!validationSchema.isValidSync(values) || this.state.submitting}
                                                        onClick={(e) => {
                                                            form.submit();
                                                        }}
                                                    >
                                                        CONTINUE
                                                    </Button>
                                                </ButtonContainers>
                                            </FormContent>
                                        ) : (
                                            <LoadingAnimation />
                                        )}

                                    </Card>

                                </FormContainer>
                            </Container>
                        );
                    }}
                />
            </>

        );
    }
}
