import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CompletedIcon } from 'modules/../images/icons/survey_step_completed.svg';
import { ReactComponent as InProgressIcon } from 'modules/../images/icons/survey_step_progress.svg';
import { ReactComponent as IncompleteIcon } from 'modules/../images/icons/survey_step_incomplete.svg';

const Container = styled.div`
  width: 600px;
  height: 50px;

  margin: 14px auto 18px auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Step = styled.div`
  width: 80px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

const Separator = styled.div`
  width: 35px;
  margin: 0 7px;
  height: 1px;
  background-color: #c4c4c4;
  margin-bottom: 21px;

  &.completed {
    background-color: #2f80ed;
  }
`;

const StepIcon = styled.div`
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const StepLabel = styled.div`
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans';
  margin-top: 9px;

  &.completed {
    font-weight: 600;
    color: #2f80ed;
  }

  &.active {
    font-weight: 600;
    color: #136afb;
  }
`;

function getStep(step, current) {
  if (step < current) {
    return <CompletedIcon />;
  }

  if (step == current) {
    return <InProgressIcon />;
  }

  if (step > current) {
    return <IncompleteIcon />;
  }
}

function getActive(step, current) {
  if (step < current) {
    return 'completed';
  }

  if (step == current) {
    return 'active';
  }

  if (step > current) {
    return '';
  }
}

export default function SurveyProgress(props) {
  let step = props.step;

  return (
    <Container>
      <Step>
        <StepIcon>{getStep(1, step)}</StepIcon>
        <StepLabel className={getActive(1, step)}>Role</StepLabel>
      </Step>
      <Separator className={getActive(1, step)}></Separator>
      <Step>
        <StepIcon>{getStep(2, step)}</StepIcon>
        <StepLabel className={getActive(2, step)}>Timeframe</StepLabel>
      </Step>
      <Separator className={getActive(2, step)}></Separator>
      <Step>
        <StepIcon>{getStep(3, step)}</StepIcon>
        <StepLabel className={getActive(3, step)}>Company</StepLabel>
      </Step>
      <Separator className={getActive(3, step)}></Separator>
      <Step>
        <StepIcon>{getStep(4, step)}</StepIcon>
        <StepLabel className={getActive(4, step)}>Schedule</StepLabel>
      </Step>
      <Separator className={getActive(4, step)}></Separator>
      <Step>
        <StepIcon>{getStep(5, step)}</StepIcon>
        <StepLabel className={getActive(5, step)}>Confirm</StepLabel>
      </Step>
    </Container>
  );
}
