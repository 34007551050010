import React from 'react';
import styled from 'styled-components';

const Input = styled.input``;

const ErrorText = styled.div`
  color: red;
  font-size: 10px;
  height: 10px;
`;

export default function TextInput(props: any) {
  const classes = 'Input ' + (props.extraClasses ? props.extraClasses : '');

  return (
    <>
      <Input
        {...props}
        placeholder={props.placeholder}
        className={classes}
        value={props.input.value}
        onChange={(e) => props.input.onChange(e)}
      />
      <ErrorText>{props.meta.error && !props.meta.pristine && 'The link is not valid'}</ErrorText>
    </>
  );
}
