import React from 'react';
import styled from 'styled-components';
import SurveyFirstStepSidebar from './SurveyFirstStepSidebar';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import CustomerSurveyRoleSelection from './CustomerSurveyRoleSelection';
import CustomerSurveyNumberSelection from './CustomerSurveyNumberSelection';
import CustomerSkillSelection from './CustomerSkillSelection';
import axios from 'axios';
import * as yup from 'yup';
import JsonDumper from '../../core/components/JsonDumper';
import SurveyProgress from './SurveyProgress';
import CommonSelect from '../../core/components/FormFields/CommonSelect';
import TimeCommitmentSelection from './TimeCommitmentSelection';
import TextInput from '../../core/components/FormFields/TextInput';
import CompanySizeSelection from './CompanySizeSelection';
import { ReactComponent as NotchLogo } from 'modules/../images/icons/notch_logo_blue.svg';
import Header from './Header';

const validationSchema = yup.object().shape({
  requiredTimeCommitment: yup.string().nullable().required(),
  expectedEngagementLength: yup.string().nullable().required(),
  expectedTimeframe: yup.string().nullable().required(),
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f8f7;
  width: 100vw;
  height: 100vh;
`;

const Card = styled.div`
  width: 1220px;
  height: 900px;
  border-radius: 10px;

  margin: -60px auto 0 auto;
`;

const CallerPicture = styled.div`
  width: 70px;
  height: 70px;

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
`;

const Thanks = styled.div`
  margin-top: 15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;

  /* identical to box height */
  text-align: center;

  color: #474a50;
`;

const BodyText = styled.div`
  width: 330px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  margin-top: 15px;
  text-align: center;
  color: #5c6371;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DashedLine = styled.div`
  width: 525px;
  border: 1px dashed #e8e8e8;
  box-sizing: border-box;
  margin-top: 40px;
`;

const Footer = styled.div`
  width: 480px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  svg {
    width: 62px;
  }

  span {
    width: 300px;
    margin-top: 23px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    color: #5c6371;
  }
`;

const LearnMore = styled.a`
  width: 110px;
  height: 40px;

  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 6px;

  font-size: 12px;
  color: #2f80ed;
  font-weight: 700;
  font-style: normal;

  &:hover {
    text-decoration: none;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export default class SurveyStep5 extends React.Component {
  state = {
    submitting: false,
  };

  isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0;
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    script.onload = () => {
      window.Calendly.initInlineWidget({
        url: 'https://calendly.com/notchteam/notch_hiring?prima\n' + 'ry_color=136afb&',
        parentElement: document.getElementById('calendly'),
        prefill: {
          name: this.props.user.fullname,
          email: this.props.user.email,
        },
      });
    };

    document.body.appendChild(script);

    window.addEventListener('message', (e) => {
      if (this.isCalendlyEvent(e)) {
        if (e.data.event === 'calendly.event_scheduled') {
          window.setTimeout(() => {
            axios.post('/api/customers/signup-survey/calendly-scheduled').then((response) => {
              this.props.refreshSurvey(response.data.data);
            });
          }, 4000);
        }
      }
    });
  }

  handleSubmit(values) {
    this.setState({
      submitting: true,
    });

    axios
      .post('/api/customers/signup-survey', values)
      .then((response) => {
        this.props.refreshSurvey(response.data.data);
        this.setState({
          submitting: false,
        });
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    let name = this.props.user.nickname;
    let link = process.env.REACT_APP_DOMAIN_URL_WEBSITE + '/how-it-works';

    return (
      <Container>
        <Header user={this.props.user} />
        <Content>
          <SurveyProgress step={4} />
          <Form
            onSubmit={(e) => {
              this.handleSubmit(e);
            }}
            mutators={{
              ...arrayMutators,
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={this.props.survey}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <>
                  <Card id="calendly"></Card>
                </>
              );
            }}
          />
        </Content>
      </Container>
    );
  }
}
