import React from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import * as yup from 'yup';
import arrayMutators from 'final-form-arrays';
import PreferredRoleSelection from '../survey2/PreferredRoleSelection';
import SurveyHeader from './SurveyHeader';
import CustomerSkillSelection from "./CustomerSkillSelection";
import SurveyItemSelection from "./SurveyItemSelection";
import SurveyOptions from "./SurveyOptions";
import JsonDumper from "../../core/components/JsonDumper";
import CommonSelect from "../../talent/survey2/CommonSelect";
import TextInput from "./TextInput";
import countries from "../../core/countries";
import {timezones} from "../../core/components/timezones_simple";

const optionsTimezone = timezones.map((i) => {
    return { value: i.value, label: i.text };
});


const FormContainer = styled.div`
  grid-area: content;
  margin: 0 auto;
  width: 100%;
  max-width: 428px;
  padding-top: 120px;
  padding-bottom: 20px;

  @media screen and (min-width: 768px) {
    max-width: 600px;
  }
`;

const HeaderText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  margin-top: 60px;

  @media screen and (min-width: 768px) {
    font-size: 27px;
    line-height: 33px;
    margin-top: 0px;
  }

  @media screen and (min-width: 1200px) {
    
  }
`;

const FormInfoText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 80px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and (min-width: 1200px) {
   
  }

  color: #5c6371;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;

const Label = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #3E4449;
  margin-bottom: 8px;
  margin-top: 16px;

  @media screen and (min-width: 1200px) {

  }
`;


const LabelInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #5c6371;
  margin-top: 4px;
  margin-bottom: 20px;

  @media screen and (min-width: 1200px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const PrimaryButton = styled.button`
  height: 57px;
  width: 100%;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  text-transform: uppercase;

  color: #ffffff;

  display: inline-flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  background: #105cf7;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  border: none;
  
  margin-top: 50px;

  &:disabled {
    background-color: #d8d8d8;
    cursor: default;
    border: none;
    outline: none;
  }
`;

const Question = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #263238;

  margin-top: 40px;
  margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 80px;
`;

const BackButton = styled.div`
  width: 150px;
  height: 57px;

  border: 1px solid #263238;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  text-transform: uppercase;

  color: #263238;
`;

const SubmitButton = styled.button`
  width: 150px;
  height: 57px;

  background: #105CF7;
  border-radius: 6px;
  
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  text-transform: uppercase;

  color: #FFFFFF;
  cursor: pointer;
  outline: none;
  border: none;
  
  &:disabled {
    background-color: #d8d8d8;
    cursor: default;
    border: none;
    outline: none;
  }
`;

function getValidationProgress(values) {

    try {
        let progress = validationSchema.validateSync(values, {abortEarly: false});
    } catch (error) {
        console.log(error.errors.length);
        return 0.4 + (0.4 - 0.4*error.errors.length/7 );
    }

    return 0.8;
}

const MultiContainer = styled.div`

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    &  > div {
      
      width: 90%;
      
      &:first-child {
        padding-right: 8px;
      }
      max-width: 50%;
      flex: 1 1 auto;
    }
  }
`;


const validationSchema = yup.object().shape({
    companyName: yup.string().required(),
    employeeNumber: yup.string().required(),
    timeCommitment: yup.string().required(),
    earliestHireDate: yup.string().required(),
    workingPeriod: yup.string().required(),
    hqCountry: yup.string().required(),
    timezone: yup.string().required(),
});

export default function SurveyStep2(props) {
  return (
    <>
      <Form
        onSubmit={(values) => {
          props.saveSurveyNext(values);
        }}
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={props.data}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <Container>
              <SurveyHeader visible={true} {...props} percentage={getValidationProgress(values)} />
              <FormContainer>

                  <HeaderText>Let us find the right match for your team</HeaderText>
                  <FormInfoText>Tell us a few more basic details about your company and the role</FormInfoText>

                  <Question>
                      What’s the name of your company?
                  </Question>
                  <MultiContainer>
                      <div>
                          <Label>
                              Company name
                          </Label>
                          <Field
                              name='companyName'
                              component={TextInput}
                          />
                      </div>
                      <div>
                          <Label>
                              Company URL
                          </Label>
                          <Field
                              name='companyURL'
                              component={TextInput}
                          />
                      </div>
                  </MultiContainer>

                  <Question>
                      How many employees in your company?
                  </Question>
                  <Field
                      name={'employeeNumber'}
                      component={SurveyItemSelection}
                      options={SurveyOptions.employeeNumber}
                  />

                  <Question>
                      How many employees in your company?
                  </Question>
                  <Field
                      name={'timeCommitment'}
                      component={SurveyItemSelection}
                      options={SurveyOptions.timeCommitment}
                  />

                  <Question>
                      How soon are you looking to hire?
                  </Question>
                  <Field
                      name={'earliestHireDate'}
                      component={SurveyItemSelection}
                      options={SurveyOptions.earliestHireDate}
                  />

                  <Question>
                      How long do you expect to work with developer from Notch?
                  </Question>
                  <Field
                      name={'workingPeriod'}
                      component={SurveyItemSelection}
                      options={SurveyOptions.workingPeriod}
                  />
                  <Label style={{margin: 0, fontSize: 12}}>Notch typically provides long-term hires, but will consider all hiring requests</Label>

                  <Question>
                      Where is your company located?
                  </Question>
                  <MultiContainer>
                      <div>
                          <Label>
                              HQ location
                          </Label>
                          <Field name='hqCountry'
                                 component={CommonSelect}
                                 options={countries}
                                 placeholder={"select from the list"}
                          />
                      </div>
                      <div>
                          <Label>
                              Working time-zone (for developer)
                          </Label>
                          <Field name='timezone'
                                 component={CommonSelect}
                                 options={optionsTimezone}
                                 placeholder={"select from the list"}
                          />
                      </div>
                  </MultiContainer>

                  <Question>
                      How did you hear about Notch?
                  </Question>
                  <Label>Optional</Label>
                  <Field name='heardFrom'
                         component={CommonSelect}
                         options={SurveyOptions.hear}
                         placeholder={"select from the list"}
                  />

                  {values.heardFrom === 'referred' && (
                      <>
                          <Label>Referrer</Label>
                          <Field
                              name='heardFromExtra'
                              component={TextInput}
                          />
                      </>
                  )}

                  {values.heardFrom === 'partner' && (
                      <>
                          <Label>Partner</Label>
                          <Field
                              name='heardFromExtra'
                              component={TextInput}
                          />
                      </>
                  )}

                  {values.heardFrom === 'other' && (
                      <>
                          <Label>Other</Label>
                          <Field
                              name='heardFromExtra'
                              component={TextInput}
                          />
                      </>
                  )}

                  <ButtonContainer>
                      <BackButton
                          onClick={()=>{
                              props.saveSurveyBack(values)
                          }}>
                          Back
                      </BackButton>
                      <SubmitButton
                          onClick={()=>{
                              form.submit();
                          }}
                          disabled={!validationSchema.isValidSync(values) || props.submitting}
                      >
                          Continue
                      </SubmitButton>
                  </ButtonContainer>
              </FormContainer>
            </Container>
          );
        }}
      />
    </>
  );
}
