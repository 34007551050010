function scrollToTop(id) {
    setTimeout(() => {
        let element = document.getElementById(id);

        if (element === null) {
            return;
        }

        let top = element.offsetTop;
        let content = document.getElementById(id);

        if (content === null) {
            return;
        }

        content.scrollTo({
            top: top - 120,
            behavior: 'auto',
        });
    }, 30);
}

export {
    scrollToTop
}