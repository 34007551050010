import React from 'react';
import JsonDumper from "../../../core/components/JsonDumper";
import {ReactComponent as FullstackGray} from "./talent_track_fullstack_gray.svg";
import {ReactComponent as FullstackBlue} from "./talent_track_fullstack_blue.svg";
import {ReactComponent as BackendGray} from "./talent_track_backend_gray.svg";
import {ReactComponent as BackendBlue} from "./talent_track_backend_blue.svg";
import {ReactComponent as FrontendGray} from "./talent_track_frontend_gray.svg";
import {ReactComponent as FrontendBlue} from "./talent_track_frontend_blue.svg";
import {ReactComponent as MobileGray} from "./talent_track_mobile_gray.svg";
import {ReactComponent as MobileBlue} from "./talent_track_mobile_blue.svg";
import {ReactComponent as DataGray} from "./talent_track_data_gray.svg";
import {ReactComponent as DataBlue} from "./talent_track_data_blue.svg";
import {ReactComponent as DevopsGray} from "./talent_track_devops_gray.svg";
import {ReactComponent as DevopsBlue} from "./talent_track_devops_blue.svg";

export default function TalentTrackIconSelector({type, active}) {

    let variation = type + "|" + (active ? "active" : "default");

    switch (variation) {
        case 'fullstack|default':
            return <FullstackGray />;
        case 'fullstack|active':
            return <FullstackBlue />;
        case 'backend|default':
            return <BackendGray />;
        case 'backend|active':
            return <BackendBlue />;
        case 'frontend|default':
            return <FrontendGray />;
        case 'frontend|active':
            return <FrontendBlue />;
        case 'mobile|default':
            return <MobileGray />;
        case 'mobile|active':
            return <MobileBlue />;
        case 'data|default':
            return <DataGray />;
        case 'data|active':
            return <DataBlue />;
        case 'devops|default':
            return <DevopsGray />;
        case 'devops|active':
            return <DevopsBlue />;
        default:
            return null;
    }

    return <JsonDumper value={variation} />
}