import styled from 'styled-components';
import SurveyVersion2Sidebar from './SurveyVersion2Sidebar';
import SurveyStep1 from "./SurveyStep1";
import SurveyStep2 from "./SurveyStep2";
import SurveyStep3 from "./SurveyStep3";
import SurveyStep5 from "./SurveyStep5";
import SurveyStep4 from "./SurveyStep4";


const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f6f8f7;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-template-areas:
    'survey'
    'survey';
  
  padding: 0 12px;

  @media screen and (min-width: 1200px) {
    grid-template-columns: 415px auto;
    grid-template-rows: 100%;
    grid-template-areas:
      'sidebar survey'
      'sidebar survey';
    padding: 0;
  }
`;

const ContainerWithoutSide = styled(Container)`
  @media screen and (min-width: 1200px) {
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-template-areas:
      'survey'
      'survey';
    padding: 0;
  }
`;

const ContentContainer = styled.div`
  grid-area: 'survey';
  overflow-y: auto;
  padding-bottom: 0px;
`;

function renderStep(props) {
  switch (props.step) {
    case 1:
      return <SurveyStep1 {...props} />;
    case 2:
      return <SurveyStep2 {...props} />;
    case 3:
      return <SurveyStep3 {...props} />;
    case 4:
      return <SurveyStep4 {...props} />;
    case 5:
      return <SurveyStep5 {...props} />;
    default:
      return null;
  }
}

function renderSidebar(props) {
  switch (props.step) {
    case 1:
      return <SurveyVersion2Sidebar screen={1}/>;
    default:
      return <SurveyVersion2Sidebar screen={2}/>;
  }
}

export default function Survey(props) {

      if (props.step <= 3) {
        return (
            <>
              <Container>
                {renderSidebar(props)}
                <ContentContainer id="survey_content">{renderStep(props)}</ContentContainer>
              </Container>
            </>
        )
      } else {
        return (
            <>
              <Container>
                  {renderSidebar(props)}
                <ContentContainer id="survey_content">{renderStep(props)}</ContentContainer>
              </Container>
            </>
        )
      }
}
