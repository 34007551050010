import React from 'react';
import styled from 'styled-components';
import JsonDumper from "../../core/components/JsonDumper";

const Input = styled.input`
  width: 100%;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #D0D5DE;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  padding: 0 10px;
  text-align: left;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #263238;
`;

const ErrorText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #FF4B55;
  height: 16px;
  margin-top: 8px;
`;

export default function TextInput(props) {
    const classes = '';

    return (
        <>
            <Input
                {...props}
                placeholder={props.placeholder}
                className={classes}
                value={props.input.value}
                onChange={(e) => props.input.onChange(e)}
            />
            <ErrorText>{props.meta.dirty && props.meta.error}</ErrorText>
        </>
    );
}
