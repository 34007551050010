import React from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Input = styled.input``;

const ErrorText = styled.div`
  color: red;
  font-size: 10px;
  height: 10px;
`;

export default function PhoneNumberInput(props) {
  const classes = 'Input ' + (props.extraClasses ? props.extraClasses : '');

  return (
    <>
      <PhoneInput
        {...props}
        placeholder={props.placeholder}
        inputClass={classes}
        value={props.input.value}
        onChange={(e) => props.input.onChange(e)}
      />
      <ErrorText>{props.meta.error && !props.meta.pristine && 'The link is not valid'}</ErrorText>
    </>
  );
}
