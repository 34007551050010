import React from "react";
import "./App.scss";
import AppContainer from "./modules/core/components/AppContainer";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <AppContainer />
    </div>
  );
}

export default App;
