import React from 'react';
import styled from 'styled-components';
import TalentAccountMenu from './TalentAccountMenu';
import SurveyProgressBar from './SurveyProgressBar';
import { ReactComponent as BlueNotchLogo } from 'modules/../images/icons/notch_logo_blue.svg';
import CustomerAccountMenu from "./CustomerAccountMenu";

const LogoContainer = styled.div`
  grid-area: logo;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const LogoContainerWithoutSide = styled(LogoContainer)`
  @media screen and (min-width: 1200px) {
    display: flex;
  }
`;

const AccountContainer = styled.div`
  grid-area: account;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: static;

  padding-left: 20px;
  
  margin-left: auto;
  
  @media screen and (min-width: 1200px) {
    margin-right: 20px;
  }
`;

const ProgressContainer = styled.div`
  grid-area: progressbar;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  width: calc(100% - 32px);
  padding: 10px 0;
  position: fixed;
  background-color: #f6f8f7;
  z-index: 2000;
  @media screen and (min-width: 1200px) {
    width: calc(100% - 415px);
  }
`;

const HeaderContainerWithoutSide = styled(HeaderContainer)`
  width: calc(100% - 32px);
  padding: 10px 0;
  position: fixed;
  background-color: #f6f8f7;
  z-index: 2000;
  @media screen and (min-width: 1200px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const Header = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 60px 40px;
  grid-template-areas:
    'logo account'
    'progressbar progressbar';

  @media screen and (min-width: 768px) {
    max-width: unset;
    width: 600px;
    max-width: 600px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60px;
    grid-template-areas:
      'logo account'
      'progressbar progressbar';
  }

  @media screen and (min-width: 768px) {
    width: 100%;
    grid-template-columns: 130px 1fr auto;
    grid-template-rows: 60px;
    grid-template-areas: 'logo progressbar account';
  }

  @media screen and (min-width: 992px) {
    width: 100%;
    max-width: 970px;
    grid-template-columns: 1fr 600px 1fr;
    grid-template-rows: 60px;
    grid-template-areas: 'logo progressbar account';
  }

  @media screen and (min-width: 1200px) {
    width: 100%;
    grid-template-columns: 1fr 600px 1fr;
    grid-template-rows: 60px;
    grid-template-areas: '_ progressbar account';
  }

  @media screen and (min-width: 1400px) {
    width: 100%;
    max-width: 100%;
    grid-template-columns: 1fr 600px 1fr;
    grid-template-rows: 60px;
    grid-template-areas: '_ progressbar account ';
  }
`;

const HeaderWithoutSide = styled(Header)`
  
  @media screen and (min-width: 1200px) {
    width: 970px;
    max-width: 970px;
    grid-template-columns: 180px 600px 180px;
    grid-template-rows: 60px;
    grid-template-areas: 'logo progressbar account';
  }

  @media screen and (min-width: 1400px) {
    width: 970px;
    max-width: 970px;
    grid-template-columns: 180px 600px 180px;
    grid-template-rows: 60px 1fr;
    grid-template-areas: 'logo progressbar account ';
  }
`;

export default function SurveyHeader(props) {

  if (props.step <= 3) {

    return (
        <HeaderContainer>
          <Header>
            <LogoContainer>
              <BlueNotchLogo/>
            </LogoContainer>
            <AccountContainer>
                <CustomerAccountMenu user={props.user}/>
            </AccountContainer>
            <ProgressContainer>
              {props.visible &&
              <SurveyProgressBar percentage={props.percentage}/>
              }
            </ProgressContainer>
          </Header>
        </HeaderContainer>
    );
  } else {
    return (
        <HeaderContainer>
            <Header>
                <LogoContainer>
                    <BlueNotchLogo/>
                </LogoContainer>
                <AccountContainer>
                    <CustomerAccountMenu user={props.user}/>
                </AccountContainer>
                <ProgressContainer>
                    {props.visible &&
                    <SurveyProgressBar percentage={props.percentage}/>
                    }
                </ProgressContainer>
            </Header>
        </HeaderContainer>
    );
  }
}
