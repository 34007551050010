import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CustomerApp from '../../customer/CustomerApp';

const BackOfficeApp = React.lazy(
  () => import(/* webpackChunkName: 'admin'*/ '../../backoffice/components/BackOfficeApp'),
);
const TalentApp = React.lazy(
  () => import(/* webpackChunkName: 'talent'*/ '../../talent/components/TalentApp'),
);
//import BackOfficeApp from "../../backoffice/components/BackOfficeApp";

const axios = require('axios').default;

interface State {
  loading: Boolean;
  user: {
    nickname: String;
    roles: Array<String>;
  } | null;
}

class AppContainer extends Component<{}, State> {
  state = {
    loading: true,
    user: {
      roles: [],
      nickname: '',
    },
  };

  componentDidMount() {
    axios
      .get('/api/app')
      .then((response: any) => {
        this.setState({
          loading: false,
          user: response.data,
        });
      })
      .catch((error: any) => {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.REACT_APP_DOMAIN_URL_WEBSITE + '/login';
          this.setState({
            loading: false,
          });
        }
      });
  }

  refreshApp() {
    axios
      .get('/api/app')
      .then((response: any) => {
        this.setState({
          loading: false,
          user: response.data,
        });
      })
      .catch((error: any) => {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.REACT_APP_DOMAIN_URL_WEBSITE + '/login';
          this.setState({
            loading: false,
          });
        }
      });
  }

  render() {
    if (this.state.loading) {
      return <div className="AppContainerLoading" />;
    }

    if (this.state.user === null) {
      window.location.href = process.env.REACT_APP_DOMAIN_URL_WEBSITE + '/login';
    }

    if (this.state.user.roles.find((role) => role === 'admin')) {
      return (
        <div>
          <Suspense fallback={<div className="AppContainerLoading" />}>
            <BackOfficeApp user={this.state.user} />
          </Suspense>
        </div>
      );
    }

    // Check if we have talent in survey state

    if (this.state.user.roles.find((role) => role === 'talent')) {
      return (
        <div>
          <Suspense fallback={<div className="AppContainerLoading" />}>
            <TalentApp user={this.state.user} refreshApp={this.refreshApp.bind(this)} />
          </Suspense>
        </div>
      );
    }

    if (this.state.user.roles.find((role) => role === 'customer')) {
      return (
        <div>
          <Suspense fallback={<div className="AppContainerLoading" />}>
            <CustomerApp user={this.state.user} refreshApp={this.refreshApp.bind(this)}/>
          </Suspense>
        </div>
      );
    }

    if (this.state.user.roles.find((role) => role === 'talent.vetting')) {
      return (
        <div>
          <Suspense fallback={<div className="AppContainerLoading" />}>
            <TalentApp user={this.state.user} />
          </Suspense>
        </div>
      );
    }

    return <Router></Router>;
  }
}

export default AppContainer;
