import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 10px;

  padding: 1px;
  border: 1px solid #d0d5de;
  border-radius: 6px;
  background-color: white;
  grid-area: 'proggressbar';
`;

const Line = styled.div`
  background: #105cf7;
  padding: 1px;
  width: ${(props) => props.percentage};
  height: 100%;
  border-radius: 6px;
  transition-duration: 800ms;
`;

export default function SurveyProgressBar({ percentage = 0 }) {
  percentage = percentage * 100 + '%';

  return (
    <Container>
      <Line percentage={percentage} />
    </Container>
  );
}
