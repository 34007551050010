import React from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import * as yup from 'yup';
import arrayMutators from 'final-form-arrays';
import PreferredRoleSelection from '../survey2/PreferredRoleSelection';
import SurveyHeader from './SurveyHeader';
import CustomerSkillSelection from "./CustomerSkillSelection";
import SurveyItemSelection from "./SurveyItemSelection";
import SurveyOptions from "./SurveyOptions";
import JsonDumper from "../../core/components/JsonDumper";

const FormContainer = styled.div`
  grid-area: content;
  margin: 0 auto;
  width: 100%;
  max-width: 428px;
  padding-top: 120px;
  padding-bottom: 20px;

  @media screen and (min-width: 768px) {
    max-width: 600px;
  }
`;

const HeaderText = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  margin-top: 40px;

  @media screen and (min-width: 768px) {
    font-size: 27px;
    line-height: 33px;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const FormInfoText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 40px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }

  color: #5c6371;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;

const Label = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-top: 30px;
  color: #263238;

  @media screen and (min-width: 1200px) {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 33px;

    color: #263238;
  }
`;

const LabelInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #5c6371;
  margin-top: 4px;
  margin-bottom: 4px;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
    line-height: 19px;
  }
`;

const PrimaryButton = styled.button`
  height: 57px;
  width: 100%;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  text-transform: uppercase;

  color: #ffffff;

  display: inline-flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  background: #105cf7;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  border: none;
  
  margin-top: 50px;

  &:disabled {
    background-color: #d8d8d8;
    cursor: default;
    border: none;
    outline: none;
  }
`;

const validationSchema = yup.object().shape({
    neededRoles: yup.array().min(1),
    neededSkills: yup.array().min(1),
    developersNeeded: yup.string().required(),
});

export default function SurveyStep1(props) {
  return (
    <>
      <Form
        onSubmit={(values) => {
          props.saveSurveyNext(values);
        }}
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={props.data}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <Container>
              <SurveyHeader visible={true} {...props} percentage={0.2} />
              <FormContainer>
                <HeaderText>
                    Find trusted
                    remote developers
                    ready to hire
                </HeaderText>
                <FormInfoText>
                    To get started, let us know a few details so we can better
                    understand your hiring needs
                </FormInfoText>
                <Label>Type of developers</Label>
                <LabelInfo>Select all that apply</LabelInfo>
                <Field name={'neededRoles'} component={PreferredRoleSelection} />

                <Label>Skills</Label>
                <LabelInfo>Select at least one</LabelInfo>
                <Field name={'neededSkills'} component={CustomerSkillSelection} options={[]} skills={props.skills} />

                  <Label style={{marginBottom: 10}}>Number of developers to hire</Label>
                  <Field name={'developersNeeded'} component={SurveyItemSelection} options={SurveyOptions.developersNeeded} />

                <PrimaryButton
                  onClick={() => {
                    form.submit();
                  }}
                  disabled={!validationSchema.isValidSync(values) || props.submitting}
                >
                  Continue
                </PrimaryButton>
              </FormContainer>
            </Container>
          );
        }}
      />
    </>
  );
}
