import React from 'react';
import Survey from './Survey';
import axios from 'axios';
import { scrollToTop } from '../../shared/Util';
import skills from "../../core/skills";

export default class SurveyPage extends React.Component {
  state = {
    loading: true,
    data: {},
    submitting: false,
    step: 0,
    skills: skills,
  };

  componentDidMount() {
    axios.get('/api/customer/signup-survey2').then((response) => {
      this.setState({
        data: response.data.survey,
        step: response.data.step,
        loading: false,
      });
    });

    setInterval(() => {
      if (this.state.skills === undefined || this.state.skills.length === 0) {
        axios.get('/api/app/select-options/skills').then((response) => {
          this.setState({
            skills: response.data.data,
          });
        });
      }
    }, 4000);
  }

  saveSurvey(survey, step) {
    this.setState({
      submitting: true,
    });

    axios.post('/api/customer/signup-survey2', { answers: survey, step: step }).then((response) => {
      this.setState({
        data: response.data.survey,
        step: response.data.step,
        submitting: false,
      });

      if (step < 4) {
        this.props.refreshApp();
      }
    });

    window.setTimeout(() => {
      scrollToTop('survey_content');
    }, 300);
  }

  saveSurveyNext(survey) {
    this.saveSurvey(survey, this.state.step + 1);
  }

  saveSurveyBack(survey) {
    this.saveSurvey(survey, this.state.step - 1);
  }

  moveToFinalStep() {
    this.setState({
      step: 5,
    });
  }

  render() {
    if (this.state.loading === true) {
      return null;
    }

    return (
      <Survey
        data={this.state.data}
        user={this.props.user}
        submitting={this.state.submitting}
        step={this.state.step}
        skills={this.state.skills}
        saveSurveyNext={(values) => {
          this.saveSurveyNext(values);
        }}
        saveSurvey={(values) => {
          this.saveSurvey(values);
        }}
        saveSurveyBack={(values) => {
          this.saveSurveyBack(values);
        }}
        moveToFinalStep={() => {
          this.moveToFinalStep();
        }}
      />
    );
  }
}
