import React, { Component } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

const ErrorText = styled.div`
  color: red;
  font-size: 10px;
  height: 10px;
`;

export default function PhoneNumberInput(props) {
  return (
    <>
      <PhoneInput
        {...props}
        country="us"
        placeholder={props.placeholder}
        inputStyle={{
          width: '100%',
          fontSize: 12,
          color: '#182135',
          border: '1px solid #c8dbe3',
          borderRadius: 7,
        }}
        containerStyle={{ width: '100%' }}
        dropdownStyle={{ backgroundColor: 'white' }}
        buttonStyle={{ backgroundColor: 'white', borderRight: 'none' }}
        value={props.input.value}
        onChange={(e) => props.input.onChange(e)}
      />
      <ErrorText>{props.meta.error && !props.meta.pristine && 'The link is not valid'}</ErrorText>
    </>
  );
}
