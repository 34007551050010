import React from 'react';
import styled from 'styled-components';

const SkillLogo = (props) => {
  var name = props.name;

  var color = Math.abs(randomHash(props.first) % 9) + 1;
  var colorClass = 'c' + (color % 9);

  const ExistingLogo = styled.img`
    width: 40px;
    height: 40px;
  `;

  const Logo = styled.div`
    width: 40px;
    height: 40px;
    background-color: yellow;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
    text-transform: uppercase;

    &.c1 {
      background-color: #0000ff;
      color: white;
    }

    &.c2 {
      background-color: #fc785d;
      color: white;
    }

    &.c3 {
      background-color: #ffd500;
      color: black;
    }

    &.c4 {
      background-color: #42e3e2;
      color: black;
    }

    &.c5 {
      background-color: #e6a822;
      color: black;
    }

    &.c6 {
      background-color: #7474e3;
      color: black;
    }

    &.c7 {
      background-color: #537699;
      color: white;
    }

    &.c8 {
      background-color: black;
      color: white;
    }

    &.c9 {
      background-color: #fad67a;
      color: white;
    }
  `;

  let logoPath = props.skill.logoPath;

  if (logoPath !== null && logoPath !== '' && logoPath !== undefined) {
    return <ExistingLogo src={'/images/icons/skills/' + logoPath} />;
  }

  name = name.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
  return <Logo className={'SkillLogo  ' + colorClass}>{name[0]}</Logo>;
};

const randomHash = (e) => {
  var hash = 0,
    i,
    chr;
  for (i = 0; i < e.length; i++) {
    chr = e.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export default SkillLogo;
