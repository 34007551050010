import React from 'react';
import styled from 'styled-components';
import SurveyFirstStepSidebar from './SurveyFirstStepSidebar';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import CustomerSurveyRoleSelection from './CustomerSurveyRoleSelection';
import CustomerSurveyNumberSelection from './CustomerSurveyNumberSelection';
import CustomerSkillSelection from './CustomerSkillSelection';
import axios from 'axios';
import * as yup from 'yup';
import JsonDumper from '../../core/components/JsonDumper';
import SurveyProgress from './SurveyProgress';
import CommonSelect from '../../core/components/FormFields/CommonSelect';
import TimeCommitmentSelection from './TimeCommitmentSelection';
import TextInput from '../../core/components/FormFields/TextInput';
import CompanySizeSelection from './CompanySizeSelection';
import { ReactComponent as NotchLogo } from 'modules/../images/icons/notch_logo_blue.svg';
import Header from './Header';

const validationSchema = yup.object().shape({
  requiredTimeCommitment: yup.string().nullable().required(),
  expectedEngagementLength: yup.string().nullable().required(),
  expectedTimeframe: yup.string().nullable().required(),
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f6f8f7;
  width: 100vw;
  height: 100vh;
`;

const Card = styled.div`
  width: 800px;
  min-height: 450px;

  border-radius: 10px;
  background-color: white;

  margin: 0 auto 40px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CallerPicture = styled.div`
  width: 70px;
  height: 70px;

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
`;

const Thanks = styled.div`
  margin-top: 15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;

  /* identical to box height */
  text-align: center;

  color: #474a50;
`;

const BodyText = styled.div`
  width: 330px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  margin-top: 15px;
  text-align: center;
  color: #5c6371;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DashedLine = styled.div`
  width: 525px;
  border: 1px dashed #e8e8e8;
  box-sizing: border-box;
  margin-top: 40px;
`;

const Footer = styled.div`
  width: 480px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  svg {
    width: 62px;
  }

  span {
    width: 300px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    color: #5c6371;
  }
`;

const LearnMore = styled.a`
  width: 110px;
  height: 40px;

  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 6px;

  font-size: 12px;
  color: #2f80ed;
  font-weight: 700;
  font-style: normal;

  &:hover {
    text-decoration: none;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export default class SurveyStep6 extends React.Component {
  state = {
    submitting: false,
  };

  componentDidMount() {}

  handleSubmit(values) {
    this.setState({
      submitting: true,
    });

    axios
      .post('/api/customers/signup-survey', values)
      .then((response) => {
        this.props.refreshSurvey(response.data.data);
        this.setState({
          submitting: false,
        });
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    let name = this.props.user.nickname;
    let link = process.env.REACT_APP_DOMAIN_URL_WEBSITE + '/how-it-works';

    return (
      <Container>
        <Header user={this.props.user} />
        <SurveyProgress step={6} />
        <Form
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
          mutators={{
            ...arrayMutators,
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          initialValues={this.props.survey}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <>
                <Card>
                  <CardContent>
                    <CallerPicture>
                      <img src="/images/141234.jpeg" />
                    </CallerPicture>
                    <Thanks>Thanks, {name}</Thanks>
                    <BodyText>
                      We’ll connect to discuss your hiring needs and growth goals in order to find
                      you the right fit for your team.
                      <br />
                      I’m looking forward to our call.
                    </BodyText>
                    <DashedLine />

                    <Footer>
                      <FooterLeft>
                        <span>
                          See how top startups are using us to source, assess, match, and manage
                          remote talent at scale.
                        </span>
                      </FooterLeft>
                      <LearnMore target="_blank" href={link}>
                        Learn more
                      </LearnMore>
                    </Footer>
                  </CardContent>
                </Card>
              </>
            );
          }}
        />
      </Container>
    );
  }
}
