import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoIcon } from 'modules/../images/icons/notch_logo_blue.svg';
import CustomerAccountMenu from './CustomerAccountMenu.tsx';

const Container = styled.div`
  min-height: 80px;

  width: 100%;

  border-bottom: 1px solid #ecefff6;
  background-color: white;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.div`
  svg {
    width: 80px;
    height: auto;
  }
  margin-left: 20px;
`;

const Profile = styled.div`
  margin-left: auto;
`;

export default class Header extends React.Component {
  render() {
    return (
      <Container>
        <Logo>
          <LogoIcon />
        </Logo>
        <Profile>
          <CustomerAccountMenu user={this.props.user} />
        </Profile>
      </Container>
    );
  }
}
