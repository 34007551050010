import React from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import * as yup from 'yup';
import arrayMutators from 'final-form-arrays';
import PreferredRoleSelection from '../survey2/PreferredRoleSelection';
import SurveyHeader from './SurveyHeader';
import CustomerSkillSelection from "./CustomerSkillSelection";
import SurveyItemSelection from "./SurveyItemSelection";
import SurveyOptions from "./SurveyOptions";
import JsonDumper from "../../core/components/JsonDumper";
import CommonSelect from "../../talent/survey2/CommonSelect";
import TextInput from "./TextInput";
import countries from "../../core/countries";
import {timezones} from "../../core/components/timezones_simple";
import PhoneNumberTextInput from "../survey/PhoneNumberTextInput";
import axios from "axios";
import { ReactComponent as CallIcon } from 'modules/../images/icons/survey_call_icon.svg';
import { ReactComponent as ScheduleIcon } from 'modules/../images/icons/survey_calendar_icon.svg';
import LoadingAnimation from "../../core/components/LoadingAnimation";

const optionsTimezone = timezones.map((i) => {
    return { value: i.value, label: i.text };
});

const CallWidget = styled.div`
  position: absolute;
  bottom: 0;
  height: 110px;
  width: 100%;
  background-color: #ebf0fa;
  margin-left: -13px;
  
  @media screen and (min-width: 1200px) {
    margin-left: 0;
    width: calc(100% - 415px);
  }
`;

const CallBackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 10px;
`;

const CallBackText = styled.span`
  font-family: Inter;
  font-size: 14px;
  color: #3e4449;
  margin: 10px 0 5px 0;
`;


const CallBackButton = styled.button`
  width: 280px;
  height: 40px;
  background-color: #105cf7;
  color: white;
  font-family: Inter;
  font-size: 14px;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 6px;
  
  &:disabled {
    background-color: #d8d8d8;
    cursor: default;
    border: none;
    outline: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`;

const LoadingContainer = styled.div`
  width: 200px;
  height: 300px;
  margin: 0 auto;
`;

const Card = styled.div`
  width: 100%;
  max-width: 649px;
  height: 613px;
  margin: 10px auto 0px auto;
  margin-bottom: 300px;
  margin-top: 40px;
  
  @media screen and (min-width: 768px) {
    width: 649px;
    height: 613px;
    margin-top: 20px;
  }

  @media screen and (min-width: 992px) {
    width: 649px;
    height: 613px;
    margin-top: 20px;
    margin-bottom: 200px;
  }

  @media screen and (min-width: 1200px) {
    width: 649px;
    height: 613px;
    margin-top: 20px;
  }

  //649px landscape
  
  @media screen and (min-width: 1400px) {
    width: 649px;
    height: 613px;
    margin-top: 20px;
  }
`;

const Header = styled.h1`
  font-family: Inter;
  font-weight: bold;
  font-size: 24px;
  color: #263238;
  margin: 150px auto 8px auto;
  width: 100%;
  
  @media screen and (min-width: 620px) {
    margin-top: 150px;
    width: 649px;
  }

  @media screen and (min-width: 768px) {
    margin-top: 100px;
    width: 649px;
    font-size: 27px;
  }
`;

const Body = styled.p`
  font-family: Inter;
  font-weight: normal;
  font-size: 14px;
  margin: 0 auto 0px auto;
  width: 100%;

  @media screen and (min-width: 620px) {
    width: 649px;
    max-width: 100%;
  }

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`;

export default class SurveyStep4 extends React.Component {

    state = {
        submitting: false,
        callAvailable: false,
        progress: 0.9,
        loading: 0,
    };

    isCalendlyEvent(e) {
        return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    scheduleNow() {
        this.setState({
            submitting: true,
        })
        
        axios.post('/api/customers/signup-survey/schedule-now', {})
            .then((response) => {
                this.setState({
                    progress: 1,
                })
                window.setTimeout(() => {
                    this.props.moveToFinalStep();
                }, 2000);
            })
    }

    componentDidMount() {

        window.setTimeout(() => {
            this.setState({
                loading: (this.state.loading === 0) ? 1 : 2,
            });
        }, 2000)
        
        axios.get('/api/customer/callback-available')
            .then((response) => {
                this.setState({
                    callAvailable: response.data.callbackAvailability,
                })
            })

        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;

        script.onload = () => {
            window.Calendly.initInlineWidget({
                url: 'https://calendly.com/notchteam/notch_hiring?prima\n' + 'ry_color=136afb&hide_event_type_details=1',
                parentElement: document.getElementById('calendly'),
                prefill: {
                    name: this.props.user.fullname,
                    email: this.props.user.email,
                },
            });
        };

        document.body.appendChild(script);

        window.addEventListener('message', (e) => {

            console.log(e.data.event);

            if (this.isCalendlyEvent(e)) {
                if (e.data.event === 'calendly.event_scheduled') {
                    this.setState({
                        progress: 1,
                    })
                    window.setTimeout(() => {
                        axios.post('/api/customers/signup-survey/calendly-scheduled').then((response) => {
                            this.props.moveToFinalStep();
                        });
                    }, 2000);
                }

                if (e.data.event === 'calendly.event_type_viewed') {
                    this.setState({
                        loading: 2,
                    })
                }
            }
        });
    }

    render() {

        let props = this.props;
        let name = this.props.user.nickname;
        let link = process.env.REACT_APP_DOMAIN_URL_WEBSITE + '/how-it-works';

        return (
            <>
                <Container>
                    <SurveyHeader visible={true} {...props} percentage={this.state.progress}/>
                    <Header>Schedule your hiring consultation</Header>
                    <Body>
                        We'll discuss your hiring needs on a 15-30 minute call. After, you'll receive profiles
                        of best fit candidates with no cost or commitment to hire.
                    </Body>
                    {this.state.loading == 1 && (<LoadingContainer><LoadingAnimation /></LoadingContainer>)}
                    <Card id="calendly">
                    </Card>
                </Container>
                {this.state.callAvailable && (
                    <CallWidget>
                        <CallBackContainer>
                            <CallBackText>
                                Prefer to speak with us now?
                            </CallBackText>
                            <CallBackButton onClick={this.scheduleNow.bind(this)} disabled={this.state.submitting}>
                                {this.state.submitting ? "SCHEDULING NOW" : "REQUEST A CALL"}
                            </CallBackButton>
                        </CallBackContainer>
                    </CallWidget>
                )}
            </>

        );
    }
}
