import React from 'react';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import * as yup from 'yup';
import arrayMutators from 'final-form-arrays';
import PreferredRoleSelection from '../survey2/PreferredRoleSelection';
import SurveyHeader from './SurveyHeader';
import CustomerSkillSelection from "./CustomerSkillSelection";
import SurveyItemSelection from "./SurveyItemSelection";
import SurveyOptions from "./SurveyOptions";
import JsonDumper from "../../core/components/JsonDumper";
import CommonSelect from "../../talent/survey2/CommonSelect";
import TextInput from "./TextInput";
import countries from "../../core/countries";
import {timezones} from "../../core/components/timezones_simple";
import PhoneNumberTextInput from "../survey/PhoneNumberTextInput";
import axios from "axios";
import { ReactComponent as CallIcon } from 'modules/../images/icons/survey_call_icon.svg';
import { ReactComponent as ScheduleIcon } from 'modules/../images/icons/survey_calendar_icon.svg';

const optionsTimezone = timezones.map((i) => {
    return { value: i.value, label: i.text };
});


const FormContainer = styled.div`
  grid-area: content;
  margin: 0 auto;
  width: 100%;
  max-width: 428px;
  padding-top: 120px;
  padding-bottom: 20px;

  @media screen and (min-width: 768px) {
    max-width: 600px;
  }
`;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;


const Card = styled.div`
  max-width: 800px;
  min-height: 450px;

  border-radius: 10px;
  background-color: white;

  margin: 0 auto 40px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 15px;
`;

const CallerPicture = styled.div`
  width: 70px;
  height: 70px;

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
`;

const Thanks = styled.div`
  margin-top: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #263238;

  /* identical to box height */
  text-align: center;
  margin-bottom: 20px;

  color: #474a50;
`;

const BodyText = styled.div`
  max-width: 450px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;

  color: #3E4449;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DashedLine = styled.div`
  width: 90%;
  border: 1px dashed #e8e8e8;
  box-sizing: border-box;
  margin-top: 20px;
`;

const Footer = styled.div`
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  svg {
    width: 62px;
  }

  span {
    width: 300px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    color: #5c6371;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const LearnMore = styled.a`
  width: 100%;
  height: 57px;

  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 6px;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #136AFB;

  &:hover {
    text-decoration: none;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media screen and (min-width: 768px) {
    width: 157px;
    height: 57px;
    font-size: 12px;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  
  margin-bottom: 40px;

  @media screen and (min-width: 768px) {
    margin-right: 20px;
    margin-bottom: 0;
    margin-top: 30px;
  }
`;

const Logo = styled.div`
  width: 100px;
  height: 100px;
`;

export default class SurveyStep5 extends React.Component{


    returnImageUrlWithSubdomain(url) {
        url = url.replace('http://', 'http://app.');
        url = url.replace('https://', 'https://app.');
        return url;
    }

    state = {
        submitting: false,
        loading: true,
        profiles: [],
    };

    componentDidMount() {

    }

    render() {

        let props = this.props;
        let name = this.props.user.fullname;
        let link = process.env.REACT_APP_DOMAIN_URL_WEBSITE + '/how-it-works';

        let callbackType = props.data.callbackType;

        if (callbackType === 'schedule_now') {
            return (
                <>
                    <Container>
                        <SurveyHeader visible={false} {...props} percentage={0.9}/>
                        <FormContainer>

                            <Card>
                                <CardContent>
                                        <Logo>
                                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="50" cy="50" r="50" fill="#136AFB"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M48.0059 32.4359C48.0059 31.1295 49.0276 30 50.3636 30H62.8133C64.1494 30 65.171 31.1295 65.171 32.4359V56.2414C65.171 59.7454 62.4609 62.6476 59.0317 62.6476C56.9608 62.6476 55.0449 61.5603 53.9118 59.7814L51.1651 55.4557V67.5641C51.1651 68.8598 50.1614 70 48.8074 70H36.3577C35.0217 70 34 68.8705 34 67.5641V43.7586C34 40.2546 36.7102 37.3524 40.1393 37.3524C42.2102 37.3524 44.1262 38.4397 45.2592 40.2186L45.2604 40.2205L48.0059 44.5443V32.4359ZM51.1651 49.5196L56.5573 58.0115L56.5583 58.0131C57.1216 58.8964 58.053 59.4097 59.0317 59.4097C60.6447 59.4097 62.0118 58.0312 62.0118 56.2414V33.2379H51.1651V49.5196ZM48.0059 50.4804L42.6137 41.9885C42.0505 41.1046 41.1184 40.5903 40.1393 40.5903C38.5263 40.5903 37.1592 41.9688 37.1592 43.7586V66.7621H48.0059V50.4804Z" fill="white"/>
                                            </svg>
                                        </Logo>
                                    <Thanks>Thanks, <b>{name}</b></Thanks>
                                    <BodyText>
                                        Your Notch hiring consultant will call you in the next <b>15 minutes.</b>
                                    </BodyText>

                                    <BodyText style={{marginTop: 30}}>

                                        We will work together to understand your hiring needs and find
                                        the best matching sofware engineer for you.
                                    </BodyText>
                                    <DashedLine />

                                    <Footer>
                                        <FooterLeft>
                                                <span>
                                                 See how other companies are<br/> building with Notch engineers today.
                                                </span>
                                        </FooterLeft>
                                        <LearnMore target="_blank" href={link}>
                                            Learn more
                                        </LearnMore>
                                    </Footer>
                                </CardContent>
                            </Card>
                        </FormContainer>
                    </Container>
                </>
            );
        } else {
            return (
                <Container>
                    <SurveyHeader visible={false} {...props} percentage={0.9}/>
                    <FormContainer>

                        <Card>
                            <CardContent>
                                <CallerPicture>
                                    <img src="/images/141234.jpeg" />
                                </CallerPicture>
                                <Thanks>Thanks, <b>{name}</b></Thanks>
                                <BodyText style={{maxWidth: 400}}>
                                    We'll discuss your hiring needs in more detail so we can start matching the best candidates. I look forward to learning more about the role and your team.
                                </BodyText>
                                <DashedLine />

                                <Footer>
                                    <FooterLeft>
                                                <span>
                                                 See how companies are simplifying <br/> hiring and recruiting with Notch
                                                </span>
                                    </FooterLeft>
                                    <LearnMore target="_blank" href={link}>
                                        Learn more
                                    </LearnMore>
                                </Footer>
                            </CardContent>
                        </Card>

                    </FormContainer>
                </Container>
            )
        }


    }
}
