// @ts-nocheck
import React, { Component } from 'react';
import JsonDumper from '../core/components/JsonDumper';
import CustomerSurvey from './survey/CustomerSurvey';
import Survey from "./survey2/Survey";
import SurveyPage from "./survey2/SurveyPage";

class CustomerApp extends Component {
  render() {
    let user = this.props.user;

    if (user.customer_status === 'in_survey') {
      return <SurveyPage user={user} refreshApp={this.props.refreshApp} />;
    }

    return null;
  }
}

export default CustomerApp;
