import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BackendIcon } from 'modules/../images/icons/title_backend_icon.svg';
import { ReactComponent as BackendIconSelected } from 'modules/../images/icons/title_backend_icon_blue.svg';
import { ReactComponent as FrontendIcon } from 'modules/../images/icons/title_frontend_icon.svg';
import { ReactComponent as FrontendIconSelected } from 'modules/../images/icons/title_frontend_icon_blue.svg';
import { ReactComponent as MobileIcon } from 'modules/../images/icons/title_mobile_icon.svg';
import { ReactComponent as MobileIconSelected } from 'modules/../images/icons/title_mobile_icon_blue.svg';
import TalentTrackIconSelector from "../../shared/icons/TalentTrackIcon/TalentTrackIconSelector";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const ItemContainer = styled.div`

  width: 50%;
  padding: 5px 0 5px 0;
  
  &:nth-child(even) {
    padding-left: 5px;
  }
  
  &:nth-child(odd) {
    padding-right: 5px;
  }

  @media screen and (min-width: 768px) {
    width: auto;
    flex: 1 1 auto;
    
    padding: 0;
    
    &:not(:last-child) {
      padding: 0 8px 0 0;
    }
    
  }
`;

const Item = styled.div`
  width: 100%;
  height: 48px;

  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(24, 33, 53, 0.05);
  border-radius: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  cursor: pointer;

  border: 2px solid white;
  
  flex:1 ;

  &.selected {
    background: rgba(47, 128, 237, 0.05);
    border: 2px solid #2f80ed;
    color: #136afb;
  }

  &:hover {
    border: 2px solid #2f90ed;
  }

  @media screen and (min-width: 768px) {
    height: 48px;
  }
`;

const ItemLogo = styled.div`
  margin-right: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 16px;

  &.selected {
    stroke: #136afb;
  }
`;

const ItemLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #5b6175;
  user-select: none;

  &.selected {
    color: #136afb;
  }

  @media screen and (min-width: 768px) {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
  }
`;

const options = [
  {
    value: 'fullstack',
    label: 'Fullstack',
    icon: 'fullstack',
    iconSelected: null,
  },
  {
    value: 'backend',
    label: 'Backend',
    icon: 'backend',
    iconSelected: <BackendIconSelected />,
  },
  {
    value: 'frontend',
    label: 'Frontend',
    icon: 'frontend',
    iconSelected: <FrontendIconSelected />,
  },
  {
    value: 'mobile',
    label: 'Mobile',
    icon: 'mobile',
    iconSelected: <MobileIconSelected />,
  },
  {
    value: 'data',
    label: 'Data',
    icon: 'data',
    iconSelected: null,
  },
  {
    value: 'devops',
    label: 'DevOps',
    icon: 'devops',
    iconSelected: null,
  },
];

function isSelected(selected, item) {
  var found = selected.find((e) => e === item);
  return found != undefined;
}

export default function PreferredRoleSelection(props) {
  let selected = props.input.value;

  if (selected === '') {
     selected = [];
  }

  return (
    <Container>
      {options.map((i) => {
        return (
            <ItemContainer>
          <Item
            key={i.value}
            className={isSelected(selected, i.value) ? 'selected' : ''}
            onClick={() => {
              if (isSelected(selected, i.value)) {
                var currentValues = [];
                Object.assign(currentValues, selected);
                currentValues = selected.filter((e) => e != i.value);
                props.input.onChange(currentValues);
              } else {
                var currentValues = [];
                Object.assign(currentValues, selected);
                currentValues.push(i.value);
                props.input.onChange(currentValues);
                console.log(currentValues);
              }
            }}
          >
            <ItemLogo>

              <TalentTrackIconSelector type={i.icon} active={isSelected(selected, i.value)}/>
            </ItemLogo>

            <ItemLabel className={isSelected(selected, i.value) ? 'selected' : ''}>
              {i.label}
            </ItemLabel>
          </Item>
            </ItemContainer>
        );
      })}
    </Container>
  );
}
