import React from 'react';
import styled from 'styled-components';
import { ReactComponent as FullstackIcon } from 'modules/../images/icons/title_fullstack_icon.svg';
import { ReactComponent as BackendIcon } from 'modules/../images/icons/title_backend_icon.svg';
import { ReactComponent as FrontendIcon } from 'modules/../images/icons/title_frontend_icon.svg';
import { ReactComponent as MobileIcon } from 'modules/../images/icons/title_mobile_icon.svg';
import SkillLogo from '../../core/components/SkillLogo';
import CommonSelect from '../../core/components/FormFields/CommonSelect';

const stylesOption = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    height: 40,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    color: 'red',
    display: 'none',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    display: state.isMulti ? 'none' : 'default',
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 40,
    border: '1px solid #eceff6',
    borderTop: 'none',
    boxShadow: 'none',
    zIndex: 1000,
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 48,
    zIndex: 1000,
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: 40,
    border: state.isFocused ? '#cbdbe3 1px solid' : '#eaecf4  1px solid',
    paddingLeft: 10,
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#7aa0ff 1px solid',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: 14,
    color: state.isDisabled ? '#999' : '#333333',
    letterSpacing: '0.3px',
    fontWeight: 400,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    fontSize: 10,
    color: '#ffffff',
    backgroundColor: '#136afb',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: 10,
    color: '#ffffff',
    backgroundColor: '#136afb',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: 10,
    lineHeight: 13,
    marginLeft: 20,
    fontWeight: 400,
    color: '#182135',
    opacity: 0.5,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 12,
    backgroundColor: state.isSelected ? '#7aa0ff' : state.isFocused ? '#edf3fc' : 'white',
    color: state.isSelected ? 'white' : state.isFocused ? '#7aa0ff' : '#6f6f6f',
  }),
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: 0 -5px 0 -5px;

  justify-content: space-between;
`;

const Item = styled.div`
  width: 95px;
  height: 95px;

  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(24, 33, 53, 0.05);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  flex-grow: 1;
  margin: 3px;

  cursor: pointer;

  &.selected {
    background: rgba(47, 128, 237, 0.05);
    border: 2px solid #2f80ed;
    color: #136afb;
  }

  &:hover {
    border: 2px solid #2f80ed;
  }
`;

const ItemLogo = styled.div`
  margin-bottom: 10px;

  img {
    width: 30px;
    height: 30px;
  }

  &.selected {
    stroke: #136afb;
  }
`;

const ItemLabel = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  user-select: none;

  color: #5b6175;

  &.selected {
    color: #136afb;
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  margin-top: 4px;
  padding: 0 5px;
`;

const main_skills = [
  'Node.js',
  'React.js',
  'Python',
  'Swift',
  'Vue.js',
  'PHP',
  'Java',
  'Typescript',
  'Golang',
  'Android',
  'Ruby',
  'Angular',
];

function mainSkillsCode(options) {
  return main_skills.map((item) => {
    let found = options.find((e) => e.label === item);
    return found.value;
  });
}

function findByName(name, skills) {
  var found = skills.find((e) => e.label === name);
  return found;
}

function isSelected(selected, item) {
  var found = selected.find((e) => e === item);
  return found != undefined;
}

function selectAvailable(selected, options) {
  return options.filter((item) => {
    return !main_skills.find((e) => e === item.label);
  });
  return options;
}

export default function CustomerSkillSelection(props) {
  let selected = props.input.value;
  let skills = props.skills;

  if (selected === '') {
    selected = [];
  }

  if (skills === null) {
    skills = [];
  }

  return (
    <>
      <Container>
        {main_skills.map((i) => {
          i = findByName(i, skills);
          if (i === undefined) {
            return null;
          }

          return (
            <Item
              key={i.value}
              className={isSelected(selected, i.value) ? 'selected' : ''}
              onClick={() => {
                if (isSelected(selected, i.value)) {
                  var currentValues = [];
                  Object.assign(currentValues, selected);
                  currentValues = selected.filter((e) => e != i.value);
                  props.input.onChange(currentValues);
                } else {
                  var currentValues = [];
                  Object.assign(currentValues, selected);
                  currentValues.push(i.value);
                  props.input.onChange(currentValues);
                }
              }}
            >
              <ItemLogo>
                <img src={'/images/icons/skills/' + i.logoPath} />
              </ItemLogo>
              <ItemLabel className={isSelected(selected, i.value) ? 'selected' : ''}>
                {i.label}
              </ItemLabel>
            </Item>
          );
        })}
        <SelectContainer>
          <CommonSelect
            options={selectAvailable(selected, skills)}
            input={{
              value: selected,
              onChange: (e) => {
                let selectedOfMainSkills = selected.filter((item) => {
                  return mainSkillsCode(skills).find((x) => x === item);
                });

                if (e === null) {
                  e = [];
                }

                let merge = e.concat(selectedOfMainSkills);
                merge = merge.filter((item, position) => {
                  return merge.indexOf(item) === position;
                });

                props.input.onChange(merge);
              },
            }}
            isMulti={true}
            placeholder="Search 100+ additional skills..."
            stylesOption={stylesOption}
          />
        </SelectContainer>
      </Container>
    </>
  );
}
